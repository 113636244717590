import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import './DetailedDash.css'; // Import the CSS file
import Feature1_1 from '../../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../../Assets/img/feature/feature1-3.png';
import Feature1_4 from '../../../Assets/img/feature/feature1-4.png';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import Dashboard from '../Dashboard/Dashboard';
import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';
import GameHandlePopup from '../../Common/GameHandlePopup';

const defaultGameTab = '75c0c41e-5d98-4d21-8e85-9c96ed240da1';

const DetailedDash = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const [selectedGameId, setSelectedGameId] = useState(defaultGameTab);
    const [gameStats, setGameStats] = useState({});
    const [gamerHandles, setGamerHandles] = useState({});
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);
    const [showPopup, setShowPopup] = useState({
        status: false,
        gameId: selectedGameId,
    });

    const closePopup = () => {
        setShowPopup({ status: false, gameId: selectedGameId });
    };

    const fetchGamerHandles = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/profile/get_gamer_handle`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            if (data.code === 200) {
                // Convert handles array to an object for easy lookup
                const handles = data.handles.reduce((acc, handle) => {
                    acc[handle.game_id] = handle.gamer_handle;
                    return acc;
                }, {});
                setGamerHandles(handles);
            } else {
                throw new Error('Failed to fetch gamer handles');
            }
        } catch (e) {
            console.error('Error fetching gamer handles:', e.message);
            setError(e.message);
        }
    };

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`);
                const data = await response.json();
                setGames([...data.games]);
                fetchGamerHandles();
            } catch (e) {
                console.error('Error fetching games:', e.message);
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []); // Run once on mount

    useEffect(() => {
        const fetchGameStats = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/user/game_stats/${selectedGameId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();
                if (data.code === 200) {
                    setGameStats({
                        tournamentsPlayed: data.user_game_stats.total_tournaments_played,
                        percentages: `${data.user_game_stats.win_percentage ?? 'N/A'}% | ${
                            data.user_game_stats.top_5_percentage ?? 'N/A'
                        }%`,
                        totalEarnings: data.user_game_stats.total_earnings ?? 'N/A',
                    });
                } else {
                    throw new Error('Failed to fetch game statistics');
                }
            } catch (e) {
                console.error('Error fetching game statistics:', e.message);
                setError(e.message);
            }
        };
        fetchGameStats();
    }, [selectedGameId, accessToken]);

    const handleGameChange = (event) => {
        setSelectedGameId(event.target.value);
    };

    const featureImages = [Feature1_1, Feature1_3, Feature1_2, Feature1_4];

    const stats = [
        {
            icon: 'fa-swords',
            label: 'Tournaments Played',
            value: gameStats.tournamentsPlayed ?? 0,
        },
        {
            icon: 'fa-trophy',
            label: 'Win % | Top 5 %',
            value: gameStats.percentages ?? 'N/A',
        },
        {
            icon: 'fa-medal',
            label: 'Total Earnings',
            value: gameStats.totalEarnings ?? 'N/A',
        },
    ];

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        // return <div>Something went wrong! Please try again later..</div>;
    }

    return (
        <div className="dashboard-page-container" style={{ backgroundColor: 'black' }}>
            <Dashboard />
            <div className="detailedDash-container">
                <div style={{ marginTop: '0px' }}>
                    <div
                        className="title-area text-center custom-anim-top wow animated"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.1s"
                    >
                        <span className="sub-title">#Game Stats</span>
                        <h2 className="sec-title">
                            From Stats to Strategy – Play Smarter <span className="text-theme"> !</span>
                        </h2>
                    </div>

                    {/* Mobile Dropdown */}
                    <div className="mobile-game-dropdown d-md-none">
                        <label htmlFor="gameSelect" className="dropdown-label">
                            Select Game:
                        </label>
                        <select
                            id="gameSelect"
                            className="form-control"
                            onChange={handleGameChange}
                            value={selectedGameId}
                        >
                            {games.map((game) => (
                                <option key={game.game_id} value={game.game_id}>
                                    {game.name}
                                </option>
                            ))}
                        </select>
                        <h5 style={{ color: '#fff', marginTop: '10px', textAlign: 'center' }}>
                            Gamer Handle:{' '}
                            <span className="text-theme"> {gamerHandles[selectedGameId] || ' -'} </span>
                            <button
                                className="dashboard-edit-button"
                                onClick={() => setShowPopup({ status: true, gameId: selectedGameId })}
                            >
                                <i className="fa fa-pencil-alt"></i>
                            </button>
                        </h5>
                    </div>

                    {/* Desktop Game Cards */}
                    <div className="game-area-container d-none d-md-block">
                        <div
                            className="game-slider-1 custom-game-card-container"
                            style={{
                                display: 'flex',
                                gap: '20px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {games.slice(0, 4).map((game, index) => (
                                <div
                                    className="custom-game-card"
                                    key={game.game_id}
                                    style={{
                                        textAlign: 'center',
                                        background: '#202020',
                                        padding: '15px',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        flex: '1',
                                        maxWidth: '300px',
                                    }}
                                    onClick={() => setSelectedGameId(game.game_id)}
                                >
                                    <img
                                        src={featureImages[index % featureImages.length]}
                                        alt="Game Feature"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                        }}
                                    />
                                    <p style={{ color: '#fff', margin: '10px 0 5px' }}>
                                        <i className="fa-solid fa-gamepad" style={{ marginRight: '15px' }}></i>{' '}
                                        {gamerHandles[game.game_id] || ' -'}{' '}
                                    </p>
                                    <button
                                        className="dashboard-gamerhandle-edit-button"
                                        onClick={() => setShowPopup({ status: true, gameId: selectedGameId })}
                                    >
                                        <i className="fa fa-pencil-alt"></i>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />

                {/* Statistics Section */}
                <div className="stats-container">
                    {stats.map((stat, index) => (
                        <div key={index} className="stat-card">
                            <i className={`fa ${stat.icon} stat-icon`}></i>
                            <p>{stat.label}</p>
                            <h4>{stat.value}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DetailedDash;
