import React, { useEffect } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import { Link } from 'react-router-dom';

const FirstLoginPopUp = ({ onClose }) => {
    useEffect(() => {
        $.magnificPopup.open({
            items: {
                src: '#QuickView',
            },
            type: 'inline',
        });
        return () => {
            localStorage.removeItem('new_user');
            if ($.magnificPopup.instance.isOpen) {
                $.magnificPopup.close();
            }
        };
    }, []);

    return (
        <>
            <div id="QuickView" className="white-popup mfp-hide">
                <div
                    className="container bg-black3 rounded-10"
                    style={{
                        maxWidth: window.innerWidth < 720 ? '90%' : '64%',
                        padding: '20px',
                    }}
                >
                    <div className="row gx-10">
                        <div className="col-lg-12 align-self-center">
                            <div
                                className="popup-padding"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                <h2
                                    className="product-title"
                                    style={{
                                        fontWeight: '800',
                                        fontSize: '40px',
                                        textAlign: 'center'
                                    }}
                                >
                                    Welcome to AgonGames
                                </h2>
                                <p
                                    className="text"
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '15px',
                                    }}
                                >
                                    Start earning for your in game skills. Kickstart with 50 Acoins from us
                                </p>
                                <div
                                    className="hiw-section"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: '20px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <div className="hiw-card">
                                        <div className="hiw-card-icon">
                                            <i className="fa fa-swords"></i>
                                        </div>
                                        <div className="hiw-content">
                                            <h3>Join a tournament</h3>
                                            <p>
                                                Start earning by winning tournaments.First 5 tournaments are free to
                                                join
                                            </p>
                                        </div>
                                    </div>

                                    <div className="hiw-card">
                                        <div className="hiw-card-icon">
                                            <i className="fa fa-dollar-sign"></i>
                                        </div>
                                        <div className="hiw-content">
                                            <h3>Great Rewards</h3>
                                            <p>
                                                Complete simple goals to earn ACoins which can be used to join
                                                tournaments
                                            </p>
                                        </div>
                                    </div>
                                    <div className="hiw-card">
                                        <div className="hiw-card-icon">
                                            <i className="fa fa-user"></i>
                                        </div>
                                        <div className="hiw-content">
                                            <h3>Complete Profile</h3>
                                            <p>
                                                Complete your profile so that other gamers can connect with you easily
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="actions"
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <button className="th-btn" onClick={onClose}>
                                        <Link
                                            onClick={onClose}
                                            className="tournaments-link"
                                            style={{
                                                color: '#0B0E13',
                                            }}
                                            to="/progames"
                                        >
                                            Let's Go
                                        </Link>
                                        <span className="icon">
                                            <i className="fa-solid fa-arrow-right ms-3"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button title="Close (Esc)" type="button" className="mfp-close text-white" onClick={onClose}>
                        ×
                    </button>
                </div>
            </div>
        </>
    );
};

export default FirstLoginPopUp;
