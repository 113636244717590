import React, { useEffect } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import JijiBg from '../../Assets/img/bg/jiji-bg2.png';

const FAQ = () => {
    useEffect(() => {
        // Apply background images from data-bg-src attribute
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }

        // Apply background colors from data-bg-color attribute
        if ($('[data-bg-color]').length > 0) {
            $('[data-bg-color]').each(function () {
                var color = $(this).attr('data-bg-color');
                $(this).css('background-color', color);
                $(this).removeAttr('data-bg-color');
            });
        }

        // Apply border color from data-border attribute
        $('[data-border]').each(function () {
            var borderColor = $(this).data('border');
            $(this).css('--th-border-color', borderColor);
        });

        // Apply mask images from data-mask-src attribute
        if ($('[data-mask-src]').length > 0) {
            $('[data-mask-src]').each(function () {
                var mask = $(this).attr('data-mask-src');
                $(this).css({
                    'mask-image': 'url(' + mask + ')',
                    '-webkit-mask-image': 'url(' + mask + ')'
                });
                $(this).addClass('bg-mask');
                $(this).removeAttr('data-mask-src');
            });
        }
    }, []); // Run once when the component mounts

    return (
        <>
            <div className="bg-repeat overflow-hidden space" style={{ marginTop: "-260px", backgroundColor: "var(--th-body-background)", textAlign: "center" }}>
                <div className="container-fluid p-0">
                    <div className="contact-wrap-1">
                        <div className="row justify-content-center align-items-start faq-page-container" style={{ margin: '0 auto' }}>
                            <div className="col-xxl-8 col-xl-10 faq-section">
                                <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <span className="sub-title style1"># Get more clarity</span>
                                    <h2 className="sec-title text-white" style={{ fontSize: "65px", fontWeight: "900" }}>About AgonGames<span className="text-theme">!</span></h2>
                                </div>
                                <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">
                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-1">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                What is AgonGames?
                                            </button>
                                        </div>
                                        <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">
                                                    AgonGames is a platform for gamers like you to compete in tournaments across your favorite games,
                                                    earn real cash rewards, and connect with a passionate gaming community.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-2">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                                How do I earn rewards?
                                            </button>
                                        </div>
                                        <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">
                                                    Play to earn: Join a tournament, top the leaderboard, and win rewards—it’s that simple! Whether you’re a casual gamer
                                                    or a pro, there’s a spot for you in AgonGames tournaments.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-3">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                                Why should I connect my game account?
                                            </button>
                                        </div>
                                        <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">
                                                    Connecting your game account helps us track your progress automatically. You can focus on playing, winning, and earning—no need to manually submit scores or screenshots!
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-4">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                                What games are supported?
                                            </button>
                                        </div>
                                        <div id="collapse-4" className="accordion-collapse collapse" aria-labelledby="collapse-item-4" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">
                                                    We support popular games like Clash of Clans, Clash Royale, and Chess.com. Have a game suggestion? Reach out to us at <a>contact@agongames.com!</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-5">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                                How do I contact you?
                                            </button>
                                        </div>
                                        <div id="collapse-5" className="accordion-collapse collapse" aria-labelledby="collapse-item-5" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">
                                                    Got suggestions, feedback, or want to work with us? Drop us an email at <b>contact@agongames.com</b>, and we'll respond promptly!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Custom CSS for FAQ */}
            <style jsx>{`
                .accordion-card {
                    margin-bottom: 15px; /* Reduce gap between accordion items */
                }
                .accordion-button {
                    padding: 15px; /* Adjust padding to reduce button size */
                }
                .accordion-body {
                    padding: 20px; /* Adjust padding inside the accordion body */
                    text-align: left; /* Align text to the left for better readability */
                }
                .faq-section {
                    max-width: 100%; /* Allow FAQ section to occupy more width */
                }
                .faq-page-container {
                    margin: 0 auto;
                    padding: 20px;
                }
            `}</style>
        </>
    );
};

export default FAQ;
