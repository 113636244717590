export const isProd = true; // process.env.ENV === 'production';

// Function to map game names to folder names
export const mapGameToFolder = (gameName) => {
    const games = {
        'clash royale': 'Clash Royale',
        cr: 'Clash Royale',
        'clash of clans': 'Clash of clans',
        coc: 'Clash of clans',
        chess: 'Chess',
        'bgmi': 'BGMI'
    };
    const lowerCaseGameName = gameName.toLowerCase();
    const folder = Object.keys(games).find((key) => lowerCaseGameName.includes(key)) || 'default';
    return games[folder];
};
