import React, { useEffect, useState, useContext } from 'react';
import './GuildLeaderboard.css'; // Import the CSS file
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom'; // For navigation
import { BASE_URL, fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import useScreenSize from '../../../Utils/Contexts/useScreenSize';

const GuildLeaderboard = () => {
    const [rewards, setRewards] = useState([]); // Store rewards data
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track error state
    const { accessToken } = useContext(AuthContext); // Access token from AuthContext
    const navigate = useNavigate(); // Navigation hook
    const isMobile = useScreenSize();
    const [guildLeaderboard, setGuildLeaderboard] = useState([]);
    const [registerLeaderboard, setRegisterLeaderboard] = useState([]);
    const [subcribeLeaderboard, setsubcribeLeaderboard] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [selectedFilter, setSelectedFilter] = useState('registrations');
    const [activeFilter, setActiveFilter] = useState('registrations');

    // Fetch rewards from the API
    useEffect(() => {
        const fetchRewards = async () => {
            try {
                const response = await fetch(`${BASE_URL}/user/rewards`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const data = await response.json();
                console.log('User Rewards data from the backend:', data);

                if (data.code === 200) {
                    setRewards(data.user_rewards); // Update rewards state
                } else {
                    throw new Error('Failed to fetch rewards');
                }
            } catch (err) {
                setError(err.message); // Set error message if API call fails
            } finally {
                setLoading(false); // Stop loading once request completes
            }
        };

        fetchRewards();
    }, [accessToken]);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setActiveFilter(filter);
        setCurrentPage(1); // Reset to page 1 when the filter changes
    };

    // Handle claim button click
    const handleClaim = (reward) => {
        // Redirection logic based on criteria
        if (!reward.claimed) {
            switch (reward.criteria) {
                case 'Join Tournaments':
                case 'Add in game name':
                    navigate('/progames');
                    break;
                case 'Update your profile':
                    navigate('/profile-form');
                    break;
                case 'Invite a friend':
                    navigate('/my-dashboard');
                    break;
                case 'Login Daily':
                    navigate('/login');
                    break;
                default:
                    navigate('/my-rewards');
                    break;
            }
        }
    };

    // get guild leaderboard
    useEffect(() => {
        const fetchGuildLeaderboardData = async () => {
            let base_url_string = `${BASE_URL}/leaderboard/guild_members?page_size=${pageSize}&page=${currentPage}`;

            try {
                const response = await fetch(base_url_string, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const result = await response.json();
                console.log('API Response for guild leaderboard:', result);
                if (response.ok) {
                    setGuildLeaderboard(result.leaderboard || []);
                } else {
                    console.error('Failed to fetch leaderboard:', result);
                }
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
                setError('Failed to fetch leaderboard.');
            }
        };

        fetchGuildLeaderboardData();
    }, [currentPage, pageSize, accessToken]);

    // get guild leaderboard
    useEffect(() => {
        const fetchGuildStatLeaderboardData = async () => {
            let base_url_string = '';
            if (selectedFilter == 'registrations') {
                base_url_string = `${BASE_URL}/leaderboard/guild_registrations?page_size=${pageSize}&page=${currentPage}`;
            } else {
                base_url_string = `${BASE_URL}/leaderboard/guild_subscriptions?page_size=${pageSize}&page=${currentPage}`;
            }

            try {
                const response = await fetch(base_url_string, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const result = await response.json();
                console.log('API Response for guild leaderboard:', result);
                if (response.ok) {
                    if (selectedFilter == 'registrations') {
                        setRegisterLeaderboard(result.leaderboard || []);
                    } else {
                        setsubcribeLeaderboard(result.leaderboard || []);
                    }
                } else {
                    console.error('Failed to fetch leaderboard:', result);
                }
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
                setError('Failed to fetch leaderboard.');
            }
        };

        fetchGuildStatLeaderboardData();
    }, [selectedFilter, currentPage, pageSize, accessToken]);

    // Display loading or error message if necessary
    if (loading) return <div>Loading rewards...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="agon-guild" style={{ backgroundColor: 'black', marginTop: '150px', marginBottom: '100px' }}>
            <div className="rewards-container">
                <div className="tabs">
                    <div className="tab active">Rewards</div>
                    {/* <div className="tab">Claimed Rewards</div> */}
                </div>

                {isMobile ? (
                    <div className="rewards-content">
                        <h2 className="rewards-title">
                            <span className="highlight">Game On, Power Up, Win Big!</span>
                        </h2>
                        <div className="rewards-list">
                            {rewards.map((reward) => (
                                <div key={reward.criteria} className="reward-item">
                                    <div className="reward-name">{reward.criteria}</div>

                                    {/* Render streak dots */}
                                    <div className="login-streak">
                                        <span className="streak-text">
                                            {reward.streak}/{reward.max_streak}
                                        </span>
                                    </div>
                                    {/* Claim button */}
                                    <button
                                        className="claim-button"
                                        disabled={reward.claimed} // Disable button if already claimed
                                        onClick={() => handleClaim(reward)}
                                    >
                                        {reward.claimed ? 'Claimed' : 'Claim'}
                                        {/* Render bonus points */}
                                        <div className="points">
                                            {reward.total_bonus > 0 ? (
                                                <span className="points-badge">
                                                    <i className="fa fa-coins"></i> {reward.total_bonus}
                                                </span>
                                            ) : (
                                                <div className="empty-dots">
                                                    <span className="streak-dot"></span>
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="rewards-content">
                        <h2 className="rewards-title">
                            <span className="highlight">Game On, Power Up, Win Big!</span>
                        </h2>
                        <div className="rewards-list">
                            {rewards.map((reward) => (
                                <div key={reward.criteria} className="reward-item">
                                    <div className="reward-name">{reward.criteria}</div>

                                    {/* Render streak dots */}
                                    <div className="login-streak">
                                        {Array.from({ length: reward.streak }).map((_, i) => (
                                            <span key={`active-${i}`} className="streak-dot active"></span> // Active dots
                                        ))}
                                        {Array.from({ length: reward.max_streak - reward.streak }).map((_, i) => (
                                            <span key={`inactive-${i}`} className="streak-dot inactive"></span> // Inactive dots
                                        ))}
                                    </div>

                                    {/* Render bonus points */}
                                    <div className="points">
                                        {reward.total_bonus > 0 ? (
                                            <span className="points-badge">
                                                <i className="fa fa-coins"></i> {reward.total_bonus}
                                            </span>
                                        ) : (
                                            <div className="empty-dots">
                                                <span className="streak-dot"></span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Claim button */}
                                    <button
                                        className="claim-button"
                                        disabled={reward.claimed} // Disable button if already claimed
                                        onClick={() => handleClaim(reward)}
                                    >
                                        {reward.claimed ? 'Claimed' : 'Claim'}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* Guild leaderboard */}
            <div>
                <div className="title-area text-center">
                    <h2 className="sec-title">Guild Members Leaderboard </h2>
                </div>
                <div className="table-container">
                    <table className="g-table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Tournament Registrations</th>
                                <th>Earnings</th>
                                <th>Subcriber</th>
                                <th>Joined On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guildLeaderboard.length > 0 ? (
                                guildLeaderboard.map((entry, index) => (
                                    <tr
                                        key={entry['member']}
                                        className={`${entry.user_row ? 'user-row-highlight' : ''}`}
                                    >
                                        <td>
                                            {entry['member']}
                                            {entry.user_row ? '(You)' : ''}
                                        </td>
                                        <td>{entry['tournament registrations']}</td>
                                        <td>{entry['total earnings']}</td>
                                        <td>{entry['subcriber'] ? 'Yes' : 'No'}</td>
                                        <td>{entry['Joined On']}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No leaderboard data available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <div className="title-area text-center">
                    <h2 className="sec-title">Leaderboard </h2>
                </div>
                <div className="col-lg-12">
                    <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                        <button
                            data-filter=".cat1"
                            className={`tab-btn th-btn style-border3 ${
                                activeFilter === 'registrations' ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => handleFilterChange('registrations')}
                        >
                            <span className="btn-border">
                                Registrations <i className="fa-solid fa-arrow-right ms-2"></i>
                            </span>
                        </button>

                        <button
                            data-filter=".cat1"
                            className={`tab-btn th-btn style-border3 ${
                                activeFilter === 'subcriptions' ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => handleFilterChange('subcriptions')}
                        >
                            <span className="btn-border">
                                Subcriptions <i className="fa-solid fa-arrow-right ms-2"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    {selectedFilter === 'registrations' ? (
                        <table className="g-table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Members</th>
                                    <th>Tournament Registrations</th>
                                    <th>Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                                {registerLeaderboard.length > 0 ? (
                                    registerLeaderboard.map((entry, index) => (
                                        <tr
                                            key={entry['username']}
                                            className={`${entry.user_row ? 'user-row-highlight' : ''}`}
                                        >
                                            <td>
                                                {entry['username']}
                                                {entry.user_row ? '(You)' : ''}
                                            </td>
                                            <td>{entry['members']}</td>
                                            <td>{entry['tournament registrations']}</td>
                                            <td>{entry['member earnings']}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No leaderboard data available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <table className="g-table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Guild Members</th>
                                    <th>Subcriptions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subcribeLeaderboard.length > 0 ? (
                                    subcribeLeaderboard.map((entry, index) => (
                                        <tr
                                            key={entry['username']}
                                            className={`${entry.user_row ? 'user-row-highlight' : ''}`}
                                        >
                                            <td>
                                                {entry['username']}
                                                {entry.user_row ? '(You)' : ''}
                                            </td>

                                            <td>{entry['members']}</td>

                                            <td>{entry['subscribers']}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No leaderboard data available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GuildLeaderboard;
