import React from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons

const HowItWorks = () => {
    return (
        <>
            <div className="hiw-container">
                <div className="sub-title style2 hiw-heading">
                    <h2># How It Works</h2>
                </div>
                <div className="hiw-cards-section">
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i class="fa-solid fa-gamepad"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Add your in-game name!</h3>
                            <p>Pick your favourite game and add your in-game name</p>
                        </div>
                    </div>
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i class="fa-solid fa-trophy"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Join any Tournament!</h3>
                            <p>Join Tournaments that fits your vibe. We’ve got tons of options!</p>
                        </div>
                    </div>
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i class="fa-solid fa-coins"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Dominate and Earn!</h3>
                            <p>Play, climb the Leaderboard, and win Real Rewards.</p>
                        </div>
                    </div>
                    {/* <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i className="fa fa-gift"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Step 4</h3>
                            <p>
                                Connect with our hardcore gaming community and
                                repeat!!
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default HowItWorks;
