import React from 'react';
import '../../App.css';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProTournamentDetails from '../../Components/ProtectedComponents/proTournamentDetails';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import { useLocation } from 'react-router-dom';
import BottomNavbar from '../../Components/Common/BottomNavbar';

const ProTournamentDetailsPage = () => {
    const location = useLocation();
    const gamerHandle = location.state?.gamerHandle;

    console.log('gamer handle received', location.state?.gamerHandle);

    return (
        <>
            <Cursor />
            <Preloader />
            <SimpleHeader3 />
            <SideMenu2 />
            {/* <ColorSwitcher/> */}
            <ProTournamentDetails tournamentGamerHandle={gamerHandle} />
            <Footer2 />
            <BottomNavbar />
            <ScrollToTop />
        </>
    );
};

export default ProTournamentDetailsPage;
