import React, { useEffect, useState, useContext } from 'react';
import '../../CustomCss/customStyles.css';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import { formatDate } from '../../Utils/timerUtils';
import { useAppContext } from '../../Utils/Contexts/appContext';
import BonusCash from '../../Assets/img/wallet/bonus_cash.png';
import RealCash from '../../Assets/img/wallet/real_cash.png';

const ProUserWallet = () => {
    const { userProfile = {}, setUserProfile } = useAppContext() || { userProfile: {}, setUserProfile: () => {} };
    const [transactions, setTransactions] = useState([]);
    const { accessToken } = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState('activity');


    // Fetch data when the component mounts
    useEffect(() => {
        if (accessToken) {
            fetchUserProfile();
            fetchTransactions();
        }
    }, [accessToken]);

    const fetchUserProfile = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                method: 'GET',
                headers: {},
            });
            const data = await response.json();
            console.log('User Profile Data:', data);

            if (data.code === 200 && data.user_data) {
                setUserProfile(data.user_data);
            } else {
                console.error('Failed to load user profile');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    const fetchTransactions = async (category = 'activity') => {
        setSelectedTab(category);
        try {
            // Construct the API URL with the category filter if provided
            let url = `${BASE_URL}/user/transactions`;
            if (category) {
                url += `?category=${category}`;
            }

            const response = await fetchWithAuth(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            console.log('Transaction Data:', data);

            if (data.code === 200 && data.activities) {
                setTransactions(data.activities);
            } else {
                console.error('Failed to load transactions');
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    return (
        <>
            <div className="row" style={{ marginTop: '150px' }}>
                <div className="col-lg-4 col-sm-10 wallet-card-container">
                    <div className="wallet-card-section">
                        <div className="wallet-card">
                            <div className="wallet-card-icon">
                            <img
                                                src={BonusCash}
                                                alt="Bonus Cash"
                                                style={{ width: '70px',  marginRight: '4px', marginTop: '20px'}}
                                            />
                            </div>
                            <div className="wallet-card-content">
                                {/* <h3>ACoins</h3> */}
                                <h3>
                                    {userProfile ? (
                                        <>
                                          {/*}  <img
                                                src={BonusCash}
                                                alt="Bonus Cash"
                                                style={{ width: '16px', height: '16px', marginRight: '4px' }}
                                            /> */}
                                            ACoins
                                        </>
                                    ) : (
                                        'Loading...'
                                    )}
                                </h3>
                                <h3 style={{color: '#45f882'}}>{userProfile?.bonus_wallet ?? 'Loading..'}</h3>
                                <p>Bonus Currency for joining paid Tournaments</p>
                            </div>
                        </div>
                        <div className="wallet-card">
                            <div className="wallet-card-icon">
                            <img
                                                src={RealCash}
                                                alt="Real Cash"
                                                style={{ width: '70px',  marginRight: '4px' , marginTop: '20px'}}
                                            />
                            </div>
                            <div className="wallet-card-content">
                                <h3>
                                    {userProfile ? (
                                        <>
                                            {/* <img
                                                src={RealCash}
                                                alt="Real Cash"
                                                style={{ width: '16px', height: '16px', marginRight: '4px' }}
                                            /> */}
                                            ACash
                                        </>
                                    ) : (
                                        'Loading...'
                                    )}
                                </h3>
                                <h3 style={{color: '#45f882'}}>{userProfile?.winnings_wallet?? 'Loading..'}</h3>
                                <p>Premium Currency for exclusive rewards</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-8 col-sm-12">
                    {/* Filter Buttons */}
                    <div className="col-lg-12">
                        <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                            <button
                                onClick={() => fetchTransactions('activity')}
                                className={`tab-btn th-btn style-border3 ${selectedTab == 'activity' ? 'active' : ''}`}
                                type="button"
                            >
                                <span className="btn-border">
                                    ACTIVITY <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => fetchTransactions('rewards')}
                                className="tab-btn th-btn style-border3"
                                type="button"
                            >
                                <span className="btn-border">
                                    REWARDS <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => fetchTransactions('payments')}
                                className="tab-btn th-btn style-border3"
                                type="button"
                            >
                                <span className="btn-border">
                                    ORDERS <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* Table */}
                    <div className="point-table-area-1 space overflow-hidden" style={{ padding: '20px', background:'none' }}>
                        <div className="container"  >
                            <div className="title-area text-center">
                                {/* <span className="sub-title style2"># Point Table</span> */}
                                <h2 className="sec-title">
                                    Your Transactions, Your Power<span className="text-theme">!</span>
                                </h2>
                            </div>
                            <div className="table-container align">
                                <table className="g-table">
                                    <thead>
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th>Description</th>
                                            <th>Txn Type</th>
                                            <th>Bonus</th>
                                            {/* <th>Deposit</th> */}
                                            <th>Winnings</th>
                                            <th>Transaction Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions.length > 0 ? (
                                            transactions.map((transaction, index) => (
                                                <tr key={transaction.transaction_id}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{transaction.description}</td>
                                                    <td>{transaction.transaction_type}</td>
                                                    <td>{transaction.bonus}</td>
                                                    {/* <td>{transaction.amount}</td> */}
                                                    <td>{transaction.winnings}</td>
                                                    <td>{formatDate(transaction.transaction_time.split(' ')[0])}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No transactions found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProUserWallet;

{
    /* <div className="wallet-card">
                    <div className="wallet-card-icon">
                        <i className="fa fa-gamepad"></i>
                    </div>
                    <h3>Deposit Wallet</h3>
                    <p>
                        {userProfile ? `$${userProfile.deposit_wallet}` : 'Loading...'}
                    </p>
                    <p>Display a calendar of upcoming tournaments with dates, times, and game.</p>
                </div>  */
}
