import React, { useEffect, useState, useContext, useRef } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import BameLogo from '../../Assets/img/logo.svg';
import MobileMenu from '../LandingPageComp/mobileMenu';
import { AuthContext } from '../../Utils/Contexts/authContext';
import SideNavbar from '../UserProfilePageComp/SideNavbar/SideNavbar';
import BonusCash from '../../Assets/img/wallet/bonus_cash.png';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import PremiumIcon from '../../Assets/img/icon/agon_premium_icon.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../Utils/Contexts/appContext';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const MainHeader2 = ({ toggleMenu, gameId }) => {
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSideNavbarOpen, setIsSideNavbarOpen] = useState(false);
    const { userProfile, setUserProfile } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const profileMenuRef = useRef(null); // Ref to detect outside clicks
    const [isMobileView, setIsMobileView] = useState(false);

    // Track screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 500); // Adjust breakpoint as needed
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const currentLocation = useLocation();

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch wallet data');

                const data = await response.json();
                setUserProfile(data.user_data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchWalletData();
    }, [accessToken]);

    const handleMenuClick = (route) => {
        if (currentLocation.pathname === route) {
            // Close the sidebar if clicking on the active route
            setIsSideNavbarOpen(false);
        } else {
            // Navigate to the selected route and close the sidebar
            navigate(route);
            setIsSideNavbarOpen(false);
        }
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                isSideNavbarOpen &&
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target) && // Ensure the click is outside the ref
                !event.target.closest('.side-navbar') // Ensure the click is not inside the sidebar
            ) {
                setIsSideNavbarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isSideNavbarOpen]);

    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSideNavbar = () => {
        console.log('button is clicked');
        setIsSideNavbarOpen(!isSideNavbarOpen);
    };

    return (
        <>
            <header className={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`}>
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container" style={{ padding: 0 }}>
                            <div
                                className="row align-items-center"
                                style={{ flexWrap: 'nowrap', justifyContent: 'space-evenly' }}
                            >
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/progames">
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <nav className="main-menu d-none d-lg-inline-block" style={{ marginLeft: '120px' }}>
                                        <ul>
                                            {/* Hide Home for Logged In users <li>
                                                <Link to="/home">HOME</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/progames">TOURNAMENTS</Link>
                                            </li>
                                            <li>
                                                <Link to="/proShop">SHOP</Link>
                                            </li>
                                            <li>
                                                <Link to="/proleaderboard">LEADERBOARD</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/agon-guilds">AGON GUILD</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/my-rewards">MY REWARDS</Link>
                                            </li>
                                            <li>
                                                <Link to="/agon-partners">AGON-PARTNERS</Link>
                                            </li>
                                            <li>
                                                <Link to="/partner-with-us">BUSINESS</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-auto d-flex align-items-center" style={{ paddingLeft: 0 }}>
                                    {!isMobileView && (
                                        <Link to="/premium-account" style={{ marginRight: '0px' }}>
                                            <img
                                                src={PremiumIcon}
                                                alt="Agon Premium"
                                                style={{ height: '18px', verticalAlign: 'middle' }}
                                            />
                                        </Link>
                                    )}
                                    {isLoading ? (
                                        <span>Loading...</span>
                                    ) : (
                                        <Link to="/my-wallet">
                                            <div className="wallet-info">
                                                <span className="wallet-item">
                                                    <img style={{ height: 18 }} src={BonusCash} alt="bonus" />{' '}
                                                    {userProfile?.bonus_wallet || 0}
                                                </span>
                                                <span className="wallet-item">
                                                    <img
                                                        style={{
                                                            height: 17,
                                                            paddingLeft: 5,
                                                            paddingBottom: 3,
                                                        }}
                                                        src={RealCash}
                                                        alt="real"
                                                    />{' '}
                                                    {userProfile?.winnings_wallet || 0}
                                                </span>
                                            </div>
                                        </Link>
                                    )}
                                    <div ref={profileMenuRef}>
                                        <a href="#" onClick={toggleSideNavbar}>
                                            <i
                                                className="fa-solid fa-user"
                                                style={{ fontSize: 'x-large', marginLeft: '5px' }}
                                            ></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
            {isSideNavbarOpen && <SideNavbar isOpen={true} handleMenuClick={handleMenuClick} />}
        </>
    );
};

export default MainHeader2;
