import React, { useState, useEffect } from 'react';
import '../../CustomCss/customStyles.css'; // Import CSS for styling
import { useRazorpay } from 'react-razorpay';
import { useAppContext } from '../../Utils/Contexts/appContext';
import { useNavigate } from 'react-router-dom';
import * as utils from '../../Utils/utils';
import { useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const PremiumAccount = () => {
    const [selectedPlan, setSelectedPlan] = useState('3 Day'); // Default to '3 Day'
    const [isPurchased, setIsPurchased] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activePlanDetails, setActivePlanDetails] = useState(null);
    const { Razorpay } = useRazorpay();
    const { accessToken } = useContext(AuthContext);
    const [alertMessage, setAlertMessage] = useState('');

    const { userProfile } = useAppContext();
    const navigate = useNavigate();
    const [disabledPlans, setDisabledPlans] = useState([]);

    useEffect(() => {
        const checkActivePlan = async () => {
            setIsLoading(true);
            if (!accessToken) {
                setIsLoading(false);
                return;
            }
            try {
                const response = await fetchWithAuth(`${BASE_URL}/active-plan`);
                if (response.ok) {
                    const data = await response.json();

                    // Check for active plan and update state
                    if (data?.has_active_plan && data?.plan?.plan_duration) {
                        setIsPurchased(true);
                        setActivePlanDetails(data.plan);
                    } else {
                        setIsPurchased(false);
                        setActivePlanDetails(null);
                    }

                    // Update disabled plans based on previously_subscribed field
                    if (data?.previously_subscribed && Array.isArray(data.previously_subscribed)) {
                        setDisabledPlans(data.previously_subscribed);

                        // Select the first enabled plan as default
                        const availablePlans = ['3 Day', '7 Day', 'Month'].filter(
                            (plan) => !data.previously_subscribed.includes(plan),
                        );
                        if (availablePlans.length > 0) {
                            setSelectedPlan(availablePlans[0]);
                        }
                    } else {
                        setDisabledPlans([]);
                        setSelectedPlan('3 Day'); // Fallback to default plan
                    }
                } else {
                    throw new Error('Failed to fetch active plan');
                }
            } catch (error) {
                console.error('Error in checkActivePlan:', error);
                setDisabledPlans([]);
                setSelectedPlan('3 Day'); // Default fallback on error
            } finally {
                setIsLoading(false);
            }
        };

        checkActivePlan();
    }, [accessToken]);

    // Plan pricing configuration
    const planPricing = {
        '3 Day': 19,
        '7 Day': 39,
        Month: 99,
    };

    // Handle plan selection
    const handlePlanChange = (plan) => {
        setSelectedPlan(plan);
    };

    // Payment handling
    const createOrder = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/create_order`, {
                method: 'POST',
                headers: {},
                body: JSON.stringify({
                    amount: planPricing[selectedPlan] * 100, // Convert to paise
                }),
            });

            if (!response.ok) {
                throw new Error('Order creation failed');
            }

            return response.json();
        } catch (error) {
            console.error('Order creation failed:', error);
            throw error;
        }
    };

    const handlePayment = async () => {
        if (!accessToken) {
            navigate('/login');
            return;
        }
        try {
            const orderData = await createOrder();
            if (!orderData) {
                throw new Error('Order creation failed');
            }

            const orderId = orderData.order.id;
            const options = {
                key: utils.isProd ? 'rzp_live_2GuKxjMekkIH4E' : 'rzp_test_SALkEXhm0sjxvq',
                amount: planPricing[selectedPlan] * 100,
                currency: 'INR',
                name: 'Agon Games',
                description: `${selectedPlan} Pro Subscription`,
                order_id: orderId,
                handler: async (response) => {
                    try {
                        const verifyResponse = await fetchWithAuth(`${BASE_URL}/payment_success`, {
                            method: 'POST',
                            headers: {},
                            body: JSON.stringify({
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: orderId,
                                plan: selectedPlan,
                            }),
                        });

                        if (!verifyResponse.ok) {
                            throw new Error('Payment verification failed');
                        }

                        // Save to localStorage and update state
                        localStorage.setItem('premiumStatus', 'true');
                        localStorage.setItem('selectedPlan', selectedPlan);
                        setIsPurchased(true);
                        alert('Payment Successful!');
                    } catch (error) {
                        console.error('Payment verification failed:', error);
                        alert('Payment verification failed!');
                    }
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9999999999',
                },
                theme: {
                    color: '#4f5882',
                },
            };

            const razorpayInstance = new Razorpay(options);
            razorpayInstance.open();
        } catch (error) {
            console.error('Payment initiation failed:', error);
            alert('Unable to initiate payment. Please try again.');
        }
    };

    return (
        <div className="premium-account-container" style={{ marginTop: '100px' }}>
            <h2 className="upgrade-title">Agon Game Pass</h2>
            <p className="upgrade-subtitle">Become Agon Game Pass holder and avail premium benefits</p>
            {isLoading ? (
                <div className="loader">Loading...</div>
            ) : isPurchased ? (
                <div className="purchased-status">
                    <h3>Congrats Agon Game Pass Holder! 🎉</h3>
                    <p>Current Plan: {activePlanDetails?.plan_duration}</p>
                    <p>Expires on: {activePlanDetails?.expires_at}</p>
                </div>
            ) : (
                <>
                    {/* Plan selection tabs */}
                    <div className="plan-tabs">
                        {['3 Day', '7 Day', 'Month'].map((plan) => (
                            <button
                                key={plan}
                                className={`plan-tab ${selectedPlan === plan ? 'active' : ''} ${
                                    disabledPlans.includes(plan) ? 'disabled' : ''
                                }`}
                                onClick={() => {
                                    if (disabledPlans.includes(plan)) {
                                        setAlertMessage('Trial is up, please purchase available plans.');
                                    } else {
                                        setSelectedPlan(plan);
                                        setAlertMessage('');
                                    }
                                }}
                            >
                                {plan}
                            </button>
                        ))}
                    </div>

                    {/* Global Alert Message */}
                    {alertMessage && <div className="subscription-alert-message">{alertMessage}</div>}

                    {/* Display dummy content based on the selected plan */}
                    <div className="plan-content">
                        {selectedPlan === '3 Day' && (
                            <p>3 Day Pass: Enjoy a short-term trial of all premium features.</p>
                        )}
                        {selectedPlan === '7 Day' && (
                            <p>7 Day Pass: A week-long access to premium features at a discounted price.</p>
                        )}
                        {selectedPlan === 'Month' && (
                            <p>Monthly Pass: Full access to all premium features for a month.</p>
                        )}
                    </div>

                    {/* Comparison table */}
                    <div className="comparison-table">
                        <div
                            className="comparison-row header"
                            style={{
                                textAlign: 'center',
                                color: '#fff',
                                backgroundColor: 'black',
                            }}
                        >
                            <div className="comparison-cell" style={{ textAlign: 'center' }}>
                                Basic
                            </div>
                            <div className="comparison-cell" style={{ color: '#45f882', textAlign: 'center' }}>
                                Agon Pass
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">Limited to 5 Free Tournaments Daily</div>
                            <div className="comparison-cell">
                                Unlimited Free Tournament Access for{' '}
                                {selectedPlan === '3 Day' ? '3 Days' : selectedPlan === '7 Day' ? '7 Days' : '1 Month'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">20% of Rewards as ACash, remaining 80% ACoins</div>
                            <div className="comparison-cell">
                                100% of Rewards in ACash (for Prize Pools & Leaderboards)
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">
                                Coupon Redemption Limited to 2 Brands (Google Play, Flipkart)
                            </div>
                            <div className="comparison-cell">{'Coupon Redemption from 100+ Popular Brands'}</div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">Basic Support</div>
                            <div className="comparison-cell">
                                {selectedPlan === '3 Day' && 'Priority Support (3 Days)'}
                                {selectedPlan === '7 Day' && 'Priority Support (7 Days)'}
                                {selectedPlan === 'Month' && '24/7 Priority Support (Monthly)'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                {selectedPlan === '3 Day' && 'Access to Weekly Mega Tournaments (3 Days)'}
                                {selectedPlan === '7 Day' && 'Access to Weekly Mega Tournaments (7 Days)'}
                                {selectedPlan === 'Month' && 'Access to Weekly Mega Tournaments (Monthly)'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">{'Access to Giveaways'}</div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">Premium Highlights on Leaderboards</div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">Player Analytics & Insights</div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                {selectedPlan === '3 Day' && 'Early Access to New Features (3 Days)'}
                                {selectedPlan === '7 Day' && 'Early Access to New Features (7 Days)'}
                                {selectedPlan === 'Month' && 'Early Access to New Features (Monthly)'}
                            </div>
                        </div>
                    </div>

                    {/* Upgrade button */}
                    <div className="upgrade-button-container">
                        <button
                            className={`upgrade-button ${disabledPlans.includes(selectedPlan) ? 'disabled' : ''}`}
                            onClick={() => {
                                if (disabledPlans.includes(selectedPlan)) {
                                    setAlertMessage('Trial is up, please purchase available plans.');
                                } else {
                                    handlePayment();
                                    setAlertMessage('');
                                }
                            }}
                        >
                            Upgrade for ₹{planPricing[selectedPlan]}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PremiumAccount;
