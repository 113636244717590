import React, { useEffect, useState, useRef } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Card1 from '../Common/Card1';
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
// import ProGameCard1 from './proGameCard1';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const ProGlobalLeaderboard = () => {
    // const { gameId: paramGameId } = useParams(); // Capture gameId from URL if available
    const leaderboardRef = useRef(null);
    const [leaderboard, setLeaderboard] = useState([]);
    const [prizes, setPrizes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [selectedGameId, setSelectedGameId] = useState(localStorage.getItem('selectedGameTab') || null);
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('WEEKLY'); // Default filter is 'upcoming'
    const [activeFilter, setActiveFilter] = useState('WEEKLY'); // Track the active filter button

    const accessToken = localStorage.getItem('accessToken');

    // Fetch list of games on mount
    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`);
                const data = await response.json();
                setGames([...data.games, ...data.games]); // Remove duplicate logic later
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    // Fetch leaderboard data when selectedGameId, currentPage, or pageSize changes
    // useEffect(() => {
    //     const fetchLeaderboardData = async () => {
    //         if (!selectedGameId) return; // Avoid fetching without a valid gameId

    //         try {
    //             const response = await fetchWithAuth(
    //                 `${BASE_URL}/global_leaderboard?page_size=${pageSize}&page=${currentPage}&game_id=${selectedGameId}`,
    //                 {
    //                     method: 'GET',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         // 'Authorization': `Bearer ${accessToken}`,
    //                     },
    //                 },
    //             );

    //             const result = await response.json();
    //             console.log('API Response for global leaderboard:', result);
    //             if (response.ok) {
    //                 setLeaderboard(result.leaderboard || []);
    //             } else {
    //                 console.error('Failed to fetch leaderboard:', result);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching leaderboard:', error);
    //             setError('Failed to fetch leaderboard.');
    //         }
    //     };

    //     fetchLeaderboardData();
    // }, [selectedGameId, currentPage, pageSize, accessToken]);

    // Fetch leaderboard data when selectedGameId, currentPage, or pageSize changes
//     useEffect(() => {
//         const fetchLeaderboardData = async () => {
//             let base_url_string = '';
//             if (!selectedGameId) {
//                 base_url_string = `${BASE_URL}/global_leaderboard?page_size=${pageSize}&page=${currentPage}`;
//             }
//             else {
//                 base_url_string =  `${BASE_URL}/global_leaderboard?page_size=${pageSize}&page=${currentPage}&game_id=${selectedGameId}`;
//             }

//             try {
//                 const response = await fetchWithAuth(
//                     base_url_string,
//                     {
//                         method: 'GET',
//                         headers: {
//                             'Content-Type': 'application/json',
//                             Authorization: `Bearer ${accessToken}`,
//                         },
//                     },
//                 );
// console.log('Access Token:', accessToken);
//                 const result = await response.json();
//                 console.log('API Response for global leaderboard:', result);
//                 if (response.ok) {
//                     setLeaderboard(result.leaderboard || []);
//                 } else {
//                     console.error('Failed to fetch leaderboard:', result);
//                 }
//             } catch (error) {
//                 console.error('Error fetching leaderboard:', error);
//                 setError('Failed to fetch leaderboard.');
//             }
//         };

//         fetchLeaderboardData();
//     }, [selectedGameId, currentPage, pageSize, accessToken]);
useEffect(() => {
    const fetchLeaderboardData = async () => {
        let base_url_string = '';
        if (!selectedGameId) {
            base_url_string = `${BASE_URL}/global_leaderboard?page_size=${pageSize}&page=${currentPage}`;
        } else {
            base_url_string = `${BASE_URL}/global_leaderboard?page_size=${pageSize}&page=${currentPage}&game_id=${selectedGameId}`;
        }

        try {
            let headers = { 'Content-Type': 'application/json' };

            // Check if the user is logged in (based on the accessToken)
            if (accessToken) {
                headers['Authorization'] = `Bearer ${accessToken}`;
            }

            const response = await fetchWithAuth(
                base_url_string,
                {
                    method: 'GET',
                    headers: headers,
                },
            );

            const result = await response.json();
            console.log('API Response for global leaderboard:', result);
            if (response.ok) {
                setLeaderboard(result.leaderboard || []);
                setPrizes(result.prizes || {});
            } else {
                console.error('Failed to fetch leaderboard:', result);
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            setError('Failed to fetch leaderboard.');
        }
    };

    fetchLeaderboardData();
}, [selectedGameId, currentPage, pageSize, accessToken]);

    // Initialize Swiper on games data change
    useEffect(() => {
        new Swiper('.game-slider', {
            slidesPerView: 3,
            spaceBetween: 24,
            loop: true,
            speed: 1000,
            autoplay: { delay: 6000, disableOnInteraction: false },
            navigation: {
                nextEl: '.game-slider-next',
                prevEl: '.game-slider-prev',
            },
            pagination: {
                el: '.game-slider-pagination',
                clickable: true,
            },
            breakpoints: {
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
            },
        });
    }, [games]);

    // Handle game click and update the selectedGameId
    // const handleGameClick = (gameId) => {
    //   setSelectedGameId(gameId);
    //   navigate(`/proleaderboard/${gameId}`); // Update the URL with the selected gameId
    // };
    const handleGameClick = (gameId) => {
        console.log('Game Id in progamecard6 : ', gameId);
        localStorage.setItem('selectedGameTab', gameId);
        // Set the selected game ID to the clicked game
        setSelectedGameId(gameId);

        // Scroll to the "leaderboard" section
        if (leaderboardRef.current) {
            leaderboardRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Variations for featureImage and gameDescription
    const featureImages = [Feature1_1, Feature1_2, Feature1_3];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    const podiumTop3 = leaderboard
        .filter((entry) => entry.prize_row) // Filter only prize_row entries
        .slice(0, 3) // Get the top 3
        .sort((a, b) => b.earnings - a.earnings); // Sort by earnings to place highest at center

    const handleFilterChange = (filter) => {
        console.log('This is my filter code');
        setSelectedFilter(filter);
        setActiveFilter(filter);
        setCurrentPage(1); // Reset to page 1 when the filter changes
    };

    // Function to calculate the next Sunday at 12 AM (midnight)
    const getNextSundayMidnight = () => {
        const now = new Date();
        const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)

        // Calculate days until the upcoming Sunday
        const daysUntilSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;

        // Get the upcoming Sunday at 12:00 AM
        const nextSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilSunday, 0, 0, 0, 0);

        return nextSunday;
    };

    // Function to calculate and format the remaining time
    const calculateTimeLeft = () => {
        const now = new Date();
        const nextSunday = getNextSundayMidnight();
        const difference = nextSunday - now;

        if (difference <= 0) {
            setTimeLeft('00:00:00');
            return;
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        // Format the time as DD:HH:MM:SS if there are days left, else HH:MM:SS
        if (days > 0) {
            // Format as "09d:10h" if time > 1 day
            setTimeLeft(`${String(days).padStart(2, '0')}d:${String(hours).padStart(2, '0')}h`);
        } else if (hours > 0) {
            // Format as "09h:10m" if time > 1 hour and < 1 day
            setTimeLeft(`${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`);
        } else {
            // Format as "08m:10s" if time < 1 hour
            setTimeLeft(`${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`);
        }
    };

    useEffect(() => {
        // Update the timer immediately and every second
        calculateTimeLeft();
        const intervalId = setInterval(calculateTimeLeft, 1000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, []);

    // // Format today's date as dd/mm/yyyy
    // const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //     const year = date.getFullYear();
    //     return `${day}/${month}/${year}`;
    // };

    const formatDate = (date) => {
        const day = date.getDate(); // Get the day of the month
        const month = date.toLocaleString("default", { month: "short" }); // Get the short month name
        const year = date.getFullYear(); // Get the full year

        // Function to determine the ordinal suffix
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

    if (isLoading) return <div>Loading.....</div>;
    if (error) return <div>Something went wrong! Please try again later.</div>;

    return (
        <>
            {/* <section className="feature-game-area-1 bg-black" style={{ marginTop: '200px', paddingBottom: '30px' }}>
        <div className="container">
          <h2 className="sec-title" style={{ textAlign: 'center', fontWeight: '700' }}>
            Compete with your passionate community and win real cash rewards<span className="text-theme">!</span>
          </h2>
          <div className="swiper game-slider">
            <div className="swiper-wrapper">
              {games.map((game, index) => (
                <div
                  className="swiper-slide"
                  key={game.game_id}
                  onClick={() => handleGameClick(game.game_id)}
                  style={{ cursor: 'pointer' }}
                >
                  <ProGameCard1
                    gameId={game.game_id}
                    featureCardBg={FeatureCardBg}
                    featureImage={featureImages[index % featureImages.length]}
                    gameTitle={game.name}
                    gameDescription={gameDescriptions[index % gameDescriptions.length]}
                    playNowLink={`/progames`}
                  />
                </div>
              ))}
            </div>
            <div className="swiper-pagination slider-pagination"></div>
            <button className="slider-arrow style3 slider-prev"><i className="fas fa-angle-left"></i></button>
            <button className="slider-arrow style3 slider-next"><i className="fas fa-angle-right"></i></button>
          </div>
        </div>
      </section> */}

            <div
                ref={leaderboardRef}
                className="point-table-area-1 space overflow-hidden"
                style={{ marginTop: '80px' }}
            >
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title style2"># Point Table</span>
                        <h2 className="sec-title">
                            Game On, Power Up, Win Big<span className="text-theme">!</span>
                        </h2>
                    </div>
                    <div className="col-lg-12">
                        <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                            <button
                                data-filter=".cat1"
                                className={`tab-btn th-btn style-border3 ${activeFilter === 'WEEKLY' ? 'active' : ''}`}
                                type="button"
                                onClick={() => handleFilterChange('WEEKLY')}
                            >
                                <span className="btn-border">
                                    WEEKLY <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>

                            <button
                                data-filter=".cat1"
                                className={`tab-btn th-btn style-border3 ${activeFilter === 'MONTHLY' ? 'active' : ''}`}
                                type="button"
                                onClick={() => handleFilterChange('MONTHLY')}
                            >
                                <span className="btn-border">
                                    MONTHLY <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>

                            <button
                                data-filter="*"
                                className={`tab-btn th-btn style-border3 ${activeFilter === 'YEARLY' ? 'active' : ''}`}
                                type="button"
                                onClick={() => handleFilterChange('YEARLY')}
                            >
                                <span className="btn-border">
                                    YEARLY <i className="fa-solid fa-arrow-right ms-2"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    {/* Podium Section for Top 3 Earners */}
                    {selectedFilter === 'WEEKLY' && podiumTop3.length > 0 && (
                        <div className="podium-section">
                            <div className="podium-card rankTwo">
                                <div className="podium-card-icon">
                                    <i class="fa-solid fa-gift" style={{ color: '#c3c1c1' }}></i>
                                </div>
                                <div className="podium-content">
                                    <h3>Rank 2</h3>
                                    <p>{prizes[2]} ACash</p>
                                </div>
                            </div>

                            <div className="podium-center-section">
                                <div className="podium-card center-podium">
                                    <div className="podium-card-icon">
                                        <i class="fa-solid fa-gift" style={{ color: '#FFD43B' }}></i>
                                    </div>
                                    <div className="podium-content">
                                        <h3>Rank 1</h3>
                                        <p>{prizes[1]} ACash</p>
                                    </div>
                                </div>
                                <div className="countdown-timer">
                                    <p className="leaderboard-timerlabel">Ending in</p>
                                    <h2 className="leaderboard-timer">{timeLeft}</h2>
                                    <h6 className="leaderboard-date">{formatDate(new Date())}</h6>
                                </div>
                            </div>

                            <div className="podium-card rankThree">
                                <div className="podium-card-icon">
                                    <i class="fa-solid fa-gift" style={{ color: '#ffa629' }}></i>
                                </div>
                                <div className="podium-content">
                                    <h3>Rank 3</h3>
                                    <p>{prizes[3]} ACash</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Timer Display */}

                    {/* Table for Leaderboard or "COMING SOON" */}
                    <div className="table-container">
                        {selectedFilter === 'WEEKLY' ? (
                            <table className="g-table">
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Username</th>
                                        <th>Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard.length > 0 ? (
                                        leaderboard.map((entry, index) => (
                                            <tr
                                                key={entry.user_id}
                                                className={`${entry.user_row ? 'user-row-highlight' : ''} ${
                                                    entry.prize_row ? 'prize-row-highlight' : ''
                                                }`}
                                            >
                                                <td>{entry.rank}</td>
                                                <td>
                                                    {entry.username} {entry.user_row ? '(You)' : ''}
                                                </td>
                                                <td>{entry.earnings}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No leaderboard data available.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <table className="g-table">
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Username</th>
                                        <th>Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="3">COMING SOON</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProGlobalLeaderboard;

// Added Auth.js
// import React , { useEffect, useState } from 'react';
// import '../../App.css';
// import { Link } from 'react-router-dom';
// import $ from 'jquery'; // Import jQuery
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'magnific-popup/dist/magnific-popup.css';
// import 'magnific-popup';
// import Waypoint from 'waypoints/lib/noframework.waypoints';
// import SmoothScroll from 'smooth-scroll';
// import WOW from 'wowjs';
// import Isotope from 'isotope-layout';
// import imagesLoaded from 'imagesloaded';
// import { gsap } from 'gsap';
// import fetchWithAuth from '../../Auth';

// const PointsTable = () => {
//   const [leaderboard, setLeaderboard] = useState([]);
//   const accessToken = localStorage.getItem('accessToken'); // Assuming you're storing accessToken in local storage
//   const gameId = 'some_game_id'; // Set this to your actual gameId value

//   // Fetch leaderboard data when the component mounts
//   useEffect(() => {
//     const fetchLeaderboardData = async () => {
//       try {
//         console.log("Coming to Points Table")
//         const response = await fetchWithAuth(`${BASE_URL}/global_leaderboard?game_id=${gameId}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//           },
//         });

//         const result = await response.json();

//         // Pass gameId as payload and hit the API - important
//         // hit the global leaderboard API for refreshing the leaderboard
//             // scene 1 - redirected to lb page from other page - refresh
//             // scene 2 - already lb page -----> auto refresh
//         // When the trnment is over , tab lb should be refreshed
//         // Change game cards to 4 cards UI except landing page
//         // UP page ---> below game cards ----> use stats API for game stats
//         // UP header ---> show wallet details from user/profile API

//         if (response.ok) {
//           console.log('Leaderboard Updated:', result);
//           setLeaderboard(result); // Assuming the API returns leaderboard data
//         } else {
//           console.error('Failed to update the leaderboard:', result);
//         }
//       } catch (error) {
//         console.error('Error during API request:', error);
//         alert('Error during API request. Please try again later.');
//       }
//     };

//     fetchLeaderboardData(); // Call the fetch function when the component mounts

//     // Your existing jQuery logic for manipulating elements
//     if ($("[data-bg-src]").length > 0) {
//       $("[data-bg-src]").each(function () {
//         var src = $(this).attr("data-bg-src");
//         $(this).css("background-image", "url(" + src + ")");
//         $(this).removeAttr("data-bg-src").addClass("background-image");
//       });
//     }

//     if ($('[data-bg-color]').length > 0) {
//       $('[data-bg-color]').each(function () {
//         var color = $(this).attr('data-bg-color');
//         $(this).css('background-color', color);
//         $(this).removeAttr('data-bg-color');
//       });
//     }

//     $('[data-border]').each(function () {
//       var borderColor = $(this).data('border');
//       $(this).css('--th-border-color', borderColor);
//     });

//     if ($('[data-mask-src]').length > 0) {
//       $('[data-mask-src]').each(function () {
//         var mask = $(this).attr('data-mask-src');
//         $(this).css({
//           'mask-image': 'url(' + mask + ')',
//           '-webkit-mask-image': 'url(' + mask + ')'
//         });
//         $(this).addClass('bg-mask');
//         $(this).removeAttr('data-mask-src');
//       });
//     }
//   }, []); // Run the useEffect hook only once when the component mounts

//   return (
//     <>
//       <div className="point-table-area-1 space overflow-hidden" data-bg-src="../../Assets/img/bg/tournament-table-sec1-bg.png" style={{marginTop:"100px"}}>
//         <div className="container">
//           <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
//             <span className="sub-title style2"># Point Table</span>
//             <h2 className="sec-title">Game On, Power Up, Win Big <span className="text-theme">!</span></h2>
//           </div>

//           <div className="table-responsive">
//             <table className="table tournament-table">
//               <thead>
//                 <tr>
//                   <th scope="col">#</th>
//                   <th scope="col">Team Squad</th>
//                   <th scope="col">Matches</th>
//                   <th scope="col">Matches</th>
//                   <th scope="col">Place PTS.</th>
//                   <th scope="col">Finishes</th>
//                   <th scope="col">Total PTS.</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {leaderboard.length > 0 ? (
//                   leaderboard.map((team, index) => (
//                     <tr key={index}>
//                       <th scope="row">{index + 1}</th>
//                       <td>
//                         <Link to="/tournament">
//                           <img src="../../Assets/img/tournament/1-1.png" alt="img" />{team.teamName}
//                         </Link>
//                       </td>
//                       <td>{team.matchesPlayed}</td>
//                       <td>{team.wins}</td>
//                       <td>{team.placePoints}</td>
//                       <td>{team.finishes}</td>
//                       <td>{team.totalPoints}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="7">No leaderboard data available.</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PointsTable;
