import React, { useEffect, useState, useContext } from 'react';
import './Guild.css'; // Import the CSS file
import useScreenSize from '../../../Utils/Contexts/useScreenSize';
import HowItWorksMobile from '../../../Components/MobileViewComp/howItWorksMobile';
import HowItWorks from '../../../Components/LandingPageComp/howItWorks';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom'; // For navigation
import { BASE_URL, fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import CountUp from 'react-countup';

const Input = styled(MuiInput)`
    width: 42px;
`;

const Guild = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '', location: '', college: '' });
    const isMobile = useScreenSize();

    const [signUps, setSignUps] = React.useState(500);
    const [newUsers, setNewUsers] = React.useState(300);
    const [oldUsers, setOldUsers] = React.useState(150);
    const [subcribers, setSubcribers] = React.useState(70);
    const [nonSubcribers, setNonSubcribers] = React.useState(40);
    const [registration, setRegistration] = React.useState(1);
    const [subcriberLeaderBoard, setSubcriberLeaderBoard] = React.useState(1);
    const [earnings, setEarnings] = React.useState(1);
    const [error, setError] = useState(null); // Track error state
    const { accessToken } = useContext(AuthContext); // Access token from AuthContext
    const navigate = useNavigate(); // Navigation hook
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSliderChangeSignUps = (event, newValue) => {
        setSignUps(newValue);
    };

    useEffect(() => {
        setEarnings(
            signUps * 1 +
                newUsers * 5 +
                oldUsers * 30 +
                subcribers * 1 +
                nonSubcribers * 1 +
                registration * 1 +
                subcriberLeaderBoard * 1,
        );
    }, [signUps, newUsers, oldUsers, subcribers, nonSubcribers, registration, subcriberLeaderBoard]);

    const handleInputChangeSignUps = (event) => {
        setSignUps(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeNewUsers = (event, newValue) => {
        setNewUsers(newValue);
    };

    const handleInputChangeNewUsers = (event) => {
        setNewUsers(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeOldUsers = (event, newValue) => {
        setOldUsers(newValue);
    };

    const handleInputChangeOldUsers = (event) => {
        setOldUsers(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeSubcribers = (event, newValue) => {
        setSubcribers(newValue);
    };

    const handleInputChangeSubcribers = (event) => {
        setSubcribers(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeNonSubcribers = (event, newValue) => {
        setNonSubcribers(newValue);
    };

    const handleInputChangeNonSubcribers = (event) => {
        setNonSubcribers(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeRegistration = (event, newValue) => {
        setRegistration(newValue);
    };

    const handleInputChangeRegistration = (event) => {
        setRegistration(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSliderChangeSubcriberLeaderBoard = (event, newValue) => {
        setSubcriberLeaderBoard(newValue);
    };

    const handleInputChangeSubcriberLeaderBoard = (event) => {
        setSubcriberLeaderBoard(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let payload = {
            location: formData.location,
            colleg: formData.college,
        };
        try {
            const response = await fetch(`${BASE_URL}/register_ambassador`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
            localStorage.setItem('ambassador', 1);
            window.location.reload();
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    // get leaderboard
    useEffect(() => {
        const fetchLeaderboardData = async () => {
            let base_url_string = `${BASE_URL}/leaderboard/guild_registrations?page_size=${pageSize}&page=${currentPage}`;
            try {
                const response = await fetch(base_url_string, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();
                if (response.ok) {
                    setLeaderboard(result.leaderboard || []);
                } else {
                    console.error('Failed to fetch leaderboard:', result);
                    setError('Failed to fetch leaderboard.');
                }
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
                setError('Failed to fetch leaderboard.');
            }
        };

        fetchLeaderboardData();
    }, [currentPage, pageSize, accessToken]);

    return (
        <div className="guild">
            <div className="th-hero-wrapper hero-1" id="hero" style={{ marginBottom: '0', marginTop: '80px' }}>
                <div className="container">
                    <div className="hero-style1 text-center">
                        <div className="title-area text-center" style={{ display: 'flex', marginBottom: '0px' }}>
                            <div className="registration-number">100,000+</div>
                            <h3 className="sub-title registration-title">Registrations</h3>
                        </div>
                        <h1 className="hero-title" style={{ marginTop: '20px' }}>
                            <span className="title2 registration-content">
                                Become A Clan Chief.
                                <br />
                                Lead a Gaming Community!
                            </span>
                        </h1>
                    </div>
                </div>
            </div>

            <div>
                <div className={`${accessToken ? 'contact-sec-1' : ''} bg-repeat overflow-hidden py-5`}>
                    <div className="container-fluid px-3 px-lg-5">
                        {' '}
                        <div className="row justify-content-center align-items-start">
                            {accessToken && (
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="title-area text-center text-lg-start mb-4">
                                        <span className="sub-title style2"># Join Us Today!</span>
                                        <h2 className="sec-title text-white">
                                            Provide Your Details <span className="text-theme">!</span>
                                        </h2>
                                    </div>
                                    <form onSubmit={handleSubmit} className="contact-form pb-xl-0 px-2 px-md-0">
                                        <div className="row g-3">
                                            <div className="form-group col-12 col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-md-6">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    name="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Location"
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="College"
                                                    name="college"
                                                    value={formData.college}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <button className="th-btn w-100">
                                                    Register <i className="fa-solid fa-arrow-right ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}

                            {/* Right Section: About & Counters */}
                            <div className="col-12 col-lg-6">
                                <div className="about-wrap4 text-center text-lg-start">
                                    <div className="title-area mb-4">
                                        <span className="sub-title style3 border-style">About AgonConnect</span>
                                        <h2 className="sec-title mb-3">
                                            India's Biggest Revenue-Sharing Platform for Gamers. Earn Up to ₹15,000 Per
                                            Month!
                                        </h2>
                                        <p>
                                            AgonConnect empowers gamers to earn real rewards by bringing others into the
                                            community and completing simple tasks. Join India's largest revenue-sharing
                                            program and start earning with ease.
                                        </p>
                                    </div>

                                    {/* Counters */}
                                    <div className="row text-center gy-4">
                                        <div className="col-6 col-md-4 col-lg-4">
                                            <div className="counter-card">
                                                <h2 className="box-number">
                                                    <CountUp start={0} end={500} duration={2} />+
                                                </h2>
                                                <p className="box-text">Total Connectors</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4">
                                            <div className="counter-card">
                                                <h2 className="box-number">
                                                    <CountUp start={0} end={50} duration={2} />
                                                    K+
                                                </h2>
                                                <p className="box-text">Rewards Distributed</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4">
                                            <div className="counter-card">
                                                <h2 className="box-number">
                                                    <CountUp start={0} end={100} duration={2} />
                                                    K+
                                                </h2>
                                                <p className="box-text">Signups Generated</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Right Section */}
                        </div>
                    </div>
                </div>
            </div>

            <div id="how-it-works">
                <HowItWorks />
            </div>

            <div>
                <div className="title-area text-center">
                    <h2 className="sec-title">Leaderboard </h2>
                </div>
                <div className="table-container">
                    <table className="g-table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Members</th>
                                <th>Tournament Registrations</th>
                                <th>Earnings</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboard.length > 0 ? (
                                leaderboard.map((entry, index) => (
                                    <tr
                                        key={entry['Guild Lead']}
                                        className={`${entry.user_row ? 'user-row-highlight' : ''}`}
                                    >
                                        <td>
                                            {entry['Guild Lead']}
                                            {entry.user_row ? '(You)' : ''}
                                        </td>
                                        <td>{entry['members']}</td>
                                        <td>{entry['tournament registrations']}</td>
                                        <td>{entry['member earnings']}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No leaderboard data available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <div className="title-area text-center">
                    <span className="sub-title style2">Agon Guild Lead - Earnings Calculator</span>
                    <h3 className="sec-title">
                        Lead your guild and Earn up to <span className="text-theme"> 12,000 </span> per month!
                    </h3>
                    <h2 className="sec-title">
                        Total Agon Guild Lead Earnings for below activity:{' '}
                        <span className="text-theme">
                            ₹<span id="earnings">{earnings}</span>
                        </span>
                    </h2>
                </div>
                <div className="calculator">
                    <label htmlFor="signups">Number of Signups (0-1000):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof signUps === 'number' ? signUps : 0}
                            onChange={handleSliderChangeSignUps}
                            aria-labelledby="input-slider"
                            min={0}
                            max={1000}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={signUps}
                            size="small"
                            onChange={handleInputChangeSignUps}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 1000,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="tournaments">Number of New Users playing 5 tournaments (0-1000):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof newUsers === 'number' ? newUsers : 0}
                            onChange={handleSliderChangeNewUsers}
                            aria-labelledby="input-slider"
                            min={0}
                            max={1000}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={newUsers}
                            size="small"
                            onChange={handleInputChangeNewUsers}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 1000,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="oldUsersTournaments">Old Users playing 5 tournaments (0-1000):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof oldUsers === 'number' ? oldUsers : 0}
                            onChange={handleSliderChangeOldUsers}
                            aria-labelledby="input-slider"
                            min={0}
                            max={1000}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={oldUsers}
                            size="small"
                            onChange={handleInputChangeOldUsers}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 1000,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="subscribersWin">Earnings from Subscribers winning tournaments (0-100):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof subcribers === 'number' ? subcribers : 0}
                            onChange={handleSliderChangeSubcribers}
                            aria-labelledby="input-slider"
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={subcribers}
                            size="small"
                            onChange={handleInputChangeSubcribers}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="nonSubscribersWin">Earnings from Non-Subscribers winning tournaments (0-50):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof nonSubcribers === 'number' ? nonSubcribers : 0}
                            onChange={handleSliderChangeNonSubcribers}
                            aria-labelledby="input-slider"
                            min={0}
                            max={50}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={nonSubcribers}
                            size="small"
                            onChange={handleInputChangeNonSubcribers}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 50,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="registrationLeaderboard">Position on Registration Leaderboard (1-10):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof registration === 'number' ? registration : 0}
                            onChange={handleSliderChangeRegistration}
                            aria-labelledby="input-slider"
                            min={0}
                            max={10}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={registration}
                            size="small"
                            onChange={handleInputChangeRegistration}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 10,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>

                    <label htmlFor="subscriberLeaderboard">Position on Subscriber Leaderboard (1-10):</label>
                    <div className="input-container">
                        <Slider
                            value={typeof subcriberLeaderBoard === 'number' ? subcriberLeaderBoard : 0}
                            onChange={handleSliderChangeSubcriberLeaderBoard}
                            aria-labelledby="input-slider"
                            min={0}
                            max={10}
                            valueLabelDisplay="auto"
                        />
                        <Input
                            value={subcriberLeaderBoard}
                            size="small"
                            onChange={handleInputChangeSubcriberLeaderBoard}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 10,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Guild;
