// import React, { useState, useEffect, useContext } from 'react';
// import { AuthContext } from '../../Utils/Contexts/authContext';
// import ProUserWallet from '../../Components/ProtectedComponents/ProUserWallet';
// import ScrollToTop from '../../Components/Common/ScrollToTop';
// import Footer2 from '../../Components/Common/Footer2';
// import ColorSwitcher from '../../Components/Common/ColorSwitcher';
// import SideMenu2 from '../../Components/Common/SideMenu2';
// import Preloader from '../../Components/Common/Preloader';
// import Cursor from '../../Components/Common/Cursor';
// import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
// import BottomNavbar from '../../Components/Common/BottomNavbar';
// import { AuthProvider } from '../../Utils/Contexts/authContext';
// import { AppProvider } from '../../Utils/Contexts/appContext';

// const ProUserWalletPage = () => {
//   return (
//     <AuthProvider>
//       <AppProvider>
//         <>
//           <Cursor />
//           <Preloader />
//           <SimpleHeader3 />
//           <SideMenu2 />
//           <ProUserWallet />
//           <BottomNavbar />
//           <Footer2 />
//           <ScrollToTop />
//         </>
//       </AppProvider>
//     </AuthProvider>
//   );
// };

// export default ProUserWalletPage;


import React from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProUserWallet from '../../Components/ProtectedComponents/ProUserWallet';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import MainHeader2 from '../../Components/Common/MainHeader2';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import BottomNavbar from '../../Components/Common/BottomNavbar';


const ProUserWalletPage = () => {
  return (
    <>
      <Cursor/>
      <Preloader/>
      <SimpleHeader3/>
      <SideMenu2/>
      {/* <ColorSwitcher/> */}
      <ProUserWallet/>
      <Footer2/>
      <ScrollToTop/>
      <BottomNavbar />
    </>
  )
}

export default ProUserWalletPage
