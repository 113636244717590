// import React, { useEffect, useState, useContext } from 'react';
// import '../DetailedDash/DetailedDash.css'; // Import the CSS file
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../../Utils/Contexts/authContext';
// import { fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';

// const Dashboard = () => {
//   const { accessToken } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const [inviteCode, setInviteCode] = useState('');

//   // Fetch user profile data when the component mounts
//   useEffect(() => {
//     fetchUserProfile();
//   }, []);

//   const fetchUserProfile = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}/user/profile`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${accessToken}`,
//         }
//       });
//       const data = await response.json();
//       console.log("User Profile Data:", data);

//       if (data.code === 200 && data.user_data) {
//         console.log("Invite code in dashboard: ", data.user_data.invite_code);
//         // Set the invite code from the response data
//         setInviteCode(data.user_data.invite_code || '');
//       } else {
//         console.error('Failed to load profile data');
//       }
//     } catch (error) {
//       console.error('Error fetching profile data:', error);
//     }
//   };

//   const handleClick = () => {
//     navigate(`/profile-form`)
//   }

//   return (
//     <div style={{ backgroundColor: "black"}}>
//       <div className="dashboard-container">
//         {/* Profile Section */}
//         <div className="dashboard-profile-card">
//           <div className="dashboard-profile-header">
//             <div className="dashboard-profile-picture">
//               <i className="fa fa-user-circle"></i>
//             </div>
//             <div className="dashboard-profile-info">
//               <h2>Sruteesh</h2>
//               <p>Beginner</p>
//             </div>
//             <button className="dashboard-edit-button" onClick={handleClick}>
//               <i className="fa fa-pencil-alt"></i>
//             </button>
//           </div>
//           <div className="dashboard-profile-stats">
//             <div className="dashboard-stat">
//               <p>Spaces</p>
//               <span>0</span>
//             </div>
//             <div className="dashboard-stat">
//               <p>Tournaments</p>
//               <span>0</span>
//             </div>
//             <div className="dashboard-stat">
//               <p>Games</p>
//               <span>1</span>
//             </div>
//           </div>
//         </div>

//         {/* Invite A Friend Section */}
//         <div className="invite-friend-card">
//           <h3>Invite A Friend</h3>
//           <p>
//             Invite a friend, and when they play their first tournament, you’ll score
//             1,000 points while they grab 500! Plus, every time they compete after
//             that, you’ll earn 20 points per game—forever. Let’s go! 🎉
//           </p>
//           <div className="invite-link-container">
//             <div className="invite-link">
//               <input
//                 type='text'
//                 placeholder='Enter Invite Link'
//                 value={`www.agongames.com/${inviteCode}`}
//                 readOnly
//               />
//             </div>
//             <div className="share-link">
//               <button className="th-btn style1 col-auto share-btn">
//                 Share <i className="fa fa-share"></i>
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// // {/* Earnings Section */}
// //         {/* <div className="earnings-card">
// //           <h3>EARNINGS</h3>
// //           <p>My current earnings</p>
// //           <div className="earnings-list">
// //             <div className="earning-item">
// //               <div className="icon-circle" style={{ backgroundColor: '#FFA500' }}>
// //                 <i className="fa fa-coins"></i>
// //               </div>
// //               <div className="earning-info">
// //                 <span>Total Coins Earned</span>
// //                 <strong>300</strong>
// //               </div>
// //             </div>
// //             <div className="earning-item">
// //               <div className="icon-circle" style={{ backgroundColor: '#4CAF50' }}>
// //                 <i className="fa fa-ticket-alt"></i>
// //               </div>
// //               <div className="earning-info">
// //                 <span>Average Entry Fee</span>
// //                 <strong>10</strong>
// //               </div>
// //             </div>
// //             <div className="earning-item">
// //               <div className="icon-circle" style={{ backgroundColor: '#2196F3' }}>
// //                 <i className="fa fa-trophy"></i>
// //               </div>
// //               <div className="earning-info">
// //                 <span>Total Coins Earned</span>
// //                 <strong>$473.00</strong>
// //               </div>
// //             </div>
// //           </div>
// //         </div> */}

import React, { useEffect, useState, useContext } from 'react';
import '../DetailedDash/DetailedDash.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { BASE_URL, fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState(''); // State to store display_name
    const [AgonXP_tier, setAgonXP_tier] = useState(''); // State to store AgonXP_tier
    const [gameStats, setGameStats] = useState({
        earnings: 0,
        tournaments: 0,
        games: 0,
    });

    useEffect(() => {
        fetchUserProfile();
        fetchGameStats();
    }, []);

    const fetchUserProfile = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();

            if (data.code === 200 && data.user_data) {
                const { invite_code, display_name, AgonXP_tier } = data.user_data;
                setInviteCode(invite_code || '');
                setUsername(display_name || 'User'); // Set username from display_name
                setAgonXP_tier(AgonXP_tier || '-'); // Set username from display_name
            } else {
                console.error('Failed to load profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const fetchGameStats = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user/game_stats`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setGameStats({
                    earnings: data.user_game_stats.total_earnings || 0,
                    tournaments: data.user_game_stats.total_tournaments_played || 0,
                    games: data.user_game_stats.total_games || 0,
                });
            } else {
                console.error('Failed to fetch game stats:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching game stats:', error);
        }
    };

    const handleClick = () => {
        navigate(`/profile-form`);
    };

    const shareLink = `https://agongames.com?r=${inviteCode}`;

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Join me on AgonGames!',
                    text: 'Hey, check out AgonGames and earn real rewards by playing tournaments across popular games!',
                    url: shareLink,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Sharing is not supported on this device.');
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(shareLink);
        alert('Referral link copied to clipboard!');
    };

    return (
        <div>
            <div className="dashboard-container">
                {/* Profile Section */}
                <div className="dashboard-profile-card">
                    <div className="dashboard-profile-header">
                        <div className="dashboard-profile-picture">
                            <i className="fa fa-user-circle"></i>
                        </div>
                        <div className="dashboard-profile-info">
                            <h2>{username}</h2>
                            <p><Link to="https://agongames.com/loyalty" className="agonxp-tier-link">{AgonXP_tier}</Link></p>
                        </div>
                        <button className="dashboard-edit-button" onClick={handleClick}>
                            <i className="fa fa-pencil-alt"></i>
                        </button>
                    </div>
                    <div className="dashboard-profile-stats">
                        <div className="dashboard-stat">
                            <p>Earnings</p>
                            <span>{gameStats.earnings}</span>
                        </div>
                        <div className="dashboard-stat">
                            <p>Tournaments</p>
                            <span>{gameStats.tournaments}</span>
                        </div>
                        <div className="dashboard-stat">
                            <p>Games</p>
                            <span>{gameStats.games}</span>
                        </div>
                    </div>
                </div>

                {/* Invite A Friend Section */}
                <div className="invite-friend-card">
                    <h3>Invite A Friend</h3>
                    <p>
                        Earn 100 ACoins when they join, plus 10 ACoins every time they compete in a tournament forever.
                        Let’s level up together! 🚀🎉
                    </p>

                    {/* Display the Share URL */}
                    <div className="share-link-container">
                        <input
                            type="text"
                            value={shareLink}
                            readOnly
                            className="share-url-input"
                            aria-label="Referral Link"
                        />
                        <button className="copy-btn" onClick={handleCopy}>
                            Copy Link
                        </button>
                    </div>

                    <button className="th-btn style1 col-auto share-btn" onClick={handleShare}>
                        Share
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

