import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { BASE_URL } from '../ApiServices/apiFunctions';
import posthog from 'posthog-js';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [country, setCountry] = useState('India');

    const fetchProductDetails = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/marketplace/rewards`);
            if (!response.ok) {
                throw new Error(`Failed to fetch rewards: ${response.status} ${response.statusText}`);
            }
            const data = await response.json();
            const vouchersWithSplitDenominations = data.rewards.data.getVouchers.data.flatMap((voucher) => {
                const denominations = voucher.valueDenominations.split(',');
                return denominations.map((denomination) => ({
                    ...voucher,
                    valueDenominations: denomination.trim(),
                }));
            });
            setProductDetails(vouchersWithSplitDenominations);
        } catch (e) {
            console.error('Error fetching product details:', e);
        }
    }, []);

    useEffect(() => {
        if (userProfile && posthog) {
            posthog.identify(
                userProfile.user_id, // Replace 'distinct_id' with your user's unique identifier
                { email: userProfile.email, phone: userProfile.phone }, // optional: set additional person properties
            );
        }
    }, [userProfile]);

    useEffect(() => {
        try {
            // Only process URL parameters if we're not already on the login page
            const url = new URL(window.location.href);
            const params = Array.from(url.searchParams.entries())
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
            console.log('URL Parameters:', params);

            const referralCode = url.searchParams.get('r');
            if (referralCode) {
                // Check if we already have this referral code to prevent unnecessary updates
                const existingCode = localStorage.getItem('referralCode');
                if (existingCode !== referralCode) {
                    localStorage.setItem('referralCode', referralCode);
                    console.log('New referral code stored:', referralCode);
                } else {
                    console.log('Referral code already exists:', referralCode);
                }
            }
        } catch (error) {
            console.error('Error processing URL parameters:', error);
        }
    });

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            return;
        }
        // navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //         const latitude = position.coords.latitude;
        //         const longitude = position.coords.longitude;
        //         fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
        //             .then((response) => response.json())
        //             .then((data) => {
        //                 const country = data.address.country;
        //                 setCountry(country);
        //             })
        //             .catch((error) => {
        //                 console.error('Error fetching location:', error);
        //                 fetch(`https://geocode.xyz/${latitude},${longitude}?geoit=json`)
        //                     .then((res) => res.json())
        //                     .then((data) => {
        //                         const country = data.country;
        //                         setCountry(country);
        //                     })
        //                     .catch((error) => {
        //                         console.error('Error fetching location:', error);
        //                     });
        //             });
        //     },
        //     (error) => {
        //         console.error('Error getting location:', error);
        fetch(`https://api.ipdata.co/?api-key=9ff8b581fb95eb0b8617fc54685b5252ef888dbecba0f2964cec2fbd`)
            .then((res) => res.json())
            .then((data) => {
                const country = data.country_name;
                localStorage.setItem('country', country);
                setCountry(country);
            })
            .catch((error) => {
                console.error('Error fetching location:', error);
            });
        //     },
        // );
    }, []);

    // Memoize the context value to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            userProfile,
            setUserProfile,
            productDetails,
            setProductDetails,
            country,
            fetchProductDetails,
        }),
        [userProfile, productDetails, country, fetchProductDetails],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Custom hook to use the app context
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
