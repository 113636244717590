import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import Timer from '../Common/Timer';
import GameHandlePopup from '../Common/GameHandlePopup';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import AlertPopup from '../Common/AlertPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'; // Line graph icon

// // Modal Component
// const Modal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal-content form-group" onClick={(e) => e.stopPropagation()}>
//         {children}
//         <button className="modal-close" onClick={onClose}>x</button>
//       </div>
//     </div>
//   );
// };

const ProTournamentCard7 = ({
    setParentActiveTab,
    gameId,
    tournamentId,
    tournamentImage,
    gameVSImg,
    subtitle1,
    title1,
    subtitle2, // Duration in hours
    title2, // Prize
    flexible,
    endTime, // Start time in "HH:MM" format
    startTime, // Start date in "MM/DD/YYYY" format
    status,
    detailsLink,
    currentPlayers,
    maxPlayers,
    entryFee,
    userRegistered,
    userRegistrationAllowed,
    ctaText,
    ctaEnabled,
    gamerHandle,
}) => {
    // const [timer, setTimer] = useState(time);
    // const [timerLabel, setTimerLabel] = useState('');
    const [showPopup, setShowPopup] = useState({
        status: false,
        gameId: gameId,
    });
    const [ctaTextComp, setCTATextComp] = useState(ctaText);
    const [ctaEnabledcomp, setCTAEnabledComp] = useState(ctaEnabled);
    const [isLoading, setIsLoading] = useState(false);
    const [newGamerHandle, setNewGamerHandle] = useState('');
    const [showGamerHandlePrompt, setShowGamerHandlePrompt] = useState(false);
    const [isRegistered, setIsRegistered] = useState(userRegistered);
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [popupTitle, setPopupTitle] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState(''); // success, warning, error

    const closePopup = () => {
        setShowPopup({ status: false, gameId: gameId });
    };

    // Register for Tournament
    const registerForTournament = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/tournaments/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ tournament_id: tournamentId }),
            });

            const data = await response.json();
            if (data.code === 200) {
                // alert('Successfully registered!');
                setPopupTitle('Success');
                setPopupMessage('Successfully registered!');
                setPopupType('success');
                // openPopup();
                setIsPopupOpen('true');
                document.body.style.overflow = 'hidden';

                setIsRegistered(true);
                setShowGamerHandlePrompt(false); // Close modal on success
                setCTATextComp(data.cta_text);
                setCTAEnabledComp(data.cta_enabled);
                return true;
            } else {
                // alert(`Failed to register: ${data.message}`);
                setPopupTitle('Whoops');
                setPopupMessage(`Failed to register: ${data.message}`);
                setPopupType('warning');
                // openPopup();
                setIsPopupOpen('true');
                document.body.style.overflow = 'hidden';
            }
        } catch (error) {
            console.error('Error registering for tournament:', error);
            setIsLoading(false);
            // alert('Failed to register for the tournament.');
            setPopupTitle('Whoops');
            setPopupMessage('Failed to register for the tournament.');
            setPopupType('warning');
            // openPopup();
            setIsPopupOpen('true');
            document.body.style.overflow = 'hidden';
        }
    };

    // Handle "Entry" button click
    const handleEnterClick = async () => {
        if (!accessToken) {
            localStorage.setItem('prevPage', window.location.pathname);
            navigate(`/login`, { state: { from: window.location.pathname } });
            return;
        }

        if (isRegistered) {
            navigate(detailsLink, { state: { gamerHandle } });
            return;
        }

        // if (!userRegistrationAllowed || isLoading) return;

        // const existingGamerHandle = await fetchGamerHandle();
        const localGamerHandle = localStorage.getItem(`gamerHandle-${gameId}`);
        console.log('current handle', localGamerHandle);

        if (gamerHandle || (localGamerHandle !== null && localGamerHandle !== '')) {
            await registerForTournament();
        } else {
            setShowPopup({ status: true, gameId: gameId });
            setShowGamerHandlePrompt(true); // Show the modal if no gamer handle exists
        }
    };

    // Handle submitting new gamer handle
    const handleSubmitGamerHandle = async () => {
        if (!newGamerHandle) {
            alert('Please enter a valid gamer handle.');
            return;
        }

        try {
            const response = await fetchWithAuth(`${BASE_URL}/profile/add_gamer_handle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    game_id: gameId,
                    gamer_handle: newGamerHandle,
                }),
            });

            const data = await response.json();
            if (data.code === 200) {
                alert(data.message);
                console.log(data.message);
                setShowGamerHandlePrompt(false);
                await registerForTournament();
            } else {
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error adding gamer handle:', error);
            alert('Failed to add gamer handle.');
        }
    };

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => setShowMessage(true);
    const handleMouseLeave = () => setShowMessage(false);
    const handleToggle = () => {
        setShowMessage(prev => !prev); // Toggle message on click/tap
    };


    return (
        <div className="col-12 filter-item cat1" style={{ marginTop: '20px', cursor: 'pointer' }}>
            <div className="tournament-card style2" id={`tournament-${tournamentId}`}>
                <div className="tournament-card-img" onClick={() => navigate(detailsLink, { state: { gamerHandle } })}>
                    <img src={tournamentImage} alt="tournament-image" />
                </div>
                <div
                    className="tournament-card-versus"
                    onClick={() => navigate(detailsLink, { state: { gamerHandle } })}
                >
                    <img src={gameVSImg} alt="tournament-versus" />
                </div>
                <div
                    className="tournament-card-content"
                    onClick={() => navigate(detailsLink, { state: { gamerHandle } })}
                >
                    <div className="tournament-card-details">
                        <div className="card-title-wrap text-md-end">
                            <h6 className="tournament-card-subtitle">{subtitle1}</h6>
                            <h3 className="tournament-card-title" style={{ color: 'white' }}>
                                {title1}
                            </h3>
                        </div>
                        <div className="tournament-card-date-wrap">
                            <Timer startTime={startTime} endTime={endTime}></Timer>
                        </div>
                        <div className="card-title-wrap">
                            <h6 className="tournament-card-subtitle">{subtitle2} HR</h6>
                            <h3 className="tournament-card-title" style={{ color: 'white' }}>
                            Prize:{' '}
                            {flexible && (
                            <span
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={handleMouseEnter} // Show tooltip on hover
                                onMouseLeave={handleMouseLeave} // Hide tooltip on mouse leave
                                onClick={handleMouseEnter} // Also show on click
                            >
                                {/* FontAwesome Trend-Up Icon */}
                                <FontAwesomeIcon
                                    icon={faArrowTrendUp}
                                    style={{
                                        marginRight: 5,
                                        color: '#45f882',
                                        fontSize: '1rem',
                                    }}
                                />
                                {/* Tooltip Message */}
                                {showMessage && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: '#0f1d23',
                                            color: '#45f882',
                                            padding: '5px 10px',
                                            borderRadius: '5px',
                                            fontSize: '0.9rem',
                                            whiteSpace: 'nowrap',
                                            zIndex: 10,
                                            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                        }}
                                    >
                                        Prize Pool is based on slots filled!
                                    </span>
                                )}
                            </span>)}
                            {title2}
                            <img
                                style={{
                                    width: 25,
                                    height: 17,
                                    paddingLeft: 5,
                                    paddingBottom: 3,
                                }}
                                src={RealCash}
                                alt="real"
                            />
                            </h3>
                        </div>
                    </div>
                    <div className="tournament-card-meta">
                        <span className="tournament-card-tag gradient-border">{status}</span>
                        <span className="tournament-card-score gradient-border">
                            <i class="fa-solid fa-user" style={{ marginRight: '14px' }}></i> {currentPlayers}/
                            {maxPlayers}
                        </span>
                    </div>
                </div>
                <div className="tournament-card-img register-container">
                    {/* {renderEnterButton()} */}
                    <button
                        className={`custom-button ${ctaEnabledcomp ? '' : 'disabled-btn'}`}
                        // style={{ backgroundColor: '#45F882' }} // Green when enabled
                        // onClick={() => navigate('/login')}
                        onClick={handleEnterClick}
                        disabled={!ctaEnabledcomp}
                    >
                        {ctaTextComp}
                        {/* <img
                        style={{
                         height: 17,
                        //paddingLeft: 5,
                         paddingBottom: 3,
                         }}
                         src={RealCash}
                         alt="real"    
                     /> */}
                    </button>
                </div>
            </div>

            {/* Modal for gamer handle
      <Modal isOpen={showGamerHandlePrompt} onClose={() => setShowGamerHandlePrompt(false)}>
        <div className="gamer-handle-modal">
          <h3>Enter Your Gamer Handle</h3>
          <input
            id="gamerHandle"
            type="text"
            value={newGamerHandle}
            onChange={(e) => setNewGamerHandle(e.target.value)}
            placeholder="Enter gamer handle"
            className="modal-input"
          />
          <button onClick={handleSubmitGamerHandle} className="modal-submit-btn">
            Add Handle
          </button>
        </div>
        <Link to="/find-gamer-id">How do I find Gamer Id?</Link>
      </Modal> */}
            <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />
        </div>
    );
};

export default ProTournamentCard7;
