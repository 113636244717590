import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BASE_URL } from "../../Utils/ApiServices/apiFunctions"; // API Base URL
import FAQ from "../FAQPageComp/faq"; // Import the FAQ component

const ContactArea = () => {
  const location = useLocation(); // Get the current URL location
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [headingText, setHeadingText] = useState("");
  const [subHeadingText, setSubHeadingText] = useState("");
  const [subjectOptions, setSubjectOptions] = useState([]); // Dynamic subject options

  useEffect(() => {
    if (location.pathname === "/partner-with-us") {
      setHeadingText("Partner With Us");
      setSubHeadingText("Partner with us in multiple ways!");
      setSubjectOptions([
        { value: "Sponsorship", label: "Sponsor/Host Tournaments" },
        { value: "Collaboration", label: "Games looking for Engagement/Feedback" },
        { value: "Other", label: "Other" },
      ]);
    } else if (location.pathname === "/contact") {
      setHeadingText("Contact Us");
      setSubHeadingText("We respond real quick!");
      setSubjectOptions([
        { value: "Support", label: "Support" },
        { value: "Feedback", label: "Feedback" },
        { value: "General Inquiry", label: "General Inquiry" },
        { value: "Other", label: "Other" },
      ]);
    } else {
      setHeadingText("Contact Us");
      setSubHeadingText("We respond real quick!");
      setSubjectOptions([
        { value: "Support", label: "Support" },
        { value: "Feedback", label: "Feedback" },
        { value: "General Inquiry", label: "General Inquiry" },
        { value: "Other", label: "Other" },
      ]);
    }
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

const Popup = ({ message, onClose }) => (
    <div className="popup-overlay" onClick={onClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <p>{message}</p>
            <button onClick={onClose}>OK</button>
        </div>
    </div>
);


const [popupMessage, setPopupMessage] = useState("");
const [showPopup, setShowPopup] = useState(false);

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch(`${BASE_URL}/support/ticket`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            setPopupMessage("We have received your response! Thank you for contacting us.");
            setShowPopup(true);
            setErrorMessage("");
            setFormData({ name: "", email: "", number: "", subject: "", message: "" });
        } else {
            const result = await response.json();
            setErrorMessage(result.message || "Failed to send your message. Please try again.");
        }
    } catch (error) {
        setErrorMessage("An error occurred. Please try again later.");
    }
};

const closePopup = () => {
    setShowPopup(false);
};


  // Extracted Contact Section as a Function
  const renderContactSection = () => (
    <div className="contact-page-1 space" style={{ marginTop: "-60px" }}>
      <div
        className="contact-sec-1 secDiv space bg-repeat overflow-hidden text-center"
        data-bg-src="assets/img/bg/jiji-bg2.png"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="title-area">
                <span className="sub-title style3"># Get In Touch</span>
                <h2 className="sec-title secText text-white">
                  {headingText} <span className="text-theme">!</span>
                </h2>
                <p className="text-white">{subHeadingText}</p>
              </div>
              <form
                onSubmit={handleSubmit}
                className="contact-form ajax-contact pb-xl-0 space-bottom"
              >
                {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
                <div className="row g-3">
                  <div className="form-group style-border2 col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                    />
                    <i className="fal fa-user"></i>
                  </div>
                  <div className="form-group style-border2 col-md-6 col-12">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                      required
                    />
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="form-group style-border2 col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      required
                    />
                    <i className="fal fa-phone"></i>
                  </div>
                  <div className="form-group dropdownBtnstyle-border2 col-md-6 col-12">
                    <select
                      className="form-select dropdownSelect"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Subject</option>
                      {subjectOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <i className="fal fa-angle-down"></i>
                  </div>
                  <div className="form-group style-border2 col-12">
                    <textarea
                      placeholder="Write Message...."
                      className="form-control"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <i className="far fa-pencil"></i>
                  </div>
                  <div className="form-btn col-12">
                    <button type="submit" className="th-btn">
                      Send Message <i className="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                  </div>
                </div>
                <p className="form-messages mb-0 mt-3">
                  {successMessage && <span className="text-success">{successMessage}</span>}
                  {errorMessage && <span className="text-danger">{errorMessage}</span>}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {location.pathname === "/faq" && (
        <>
          {/* FAQ Section */}
          <div style={{ marginTop: "280px" }}>
            <FAQ />
          </div>
          <div style={{ marginTop: "-200px" }}>
          {renderContactSection()}
          </div>
        </>
      )}
      {location.pathname === "/contact" && (
        <>
          {renderContactSection()}
          {/* FAQ Section */}
          <div style={{ marginTop: "-260px" }}>
            <FAQ />
          </div>
        </>
      )}
      {location.pathname === "/partner-with-us" && <>{renderContactSection()}</>}
    </>
  );

};

export default ContactArea;
