import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import '../../App.css';
import 'jquery-ui/ui/widgets/slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import MainHeader1 from '../../Components/Common/MainHeader1';
import GameArea2 from '../../Components/GamePageComp/gameArea2';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';

const GamesPage = () => {
    const { accessToken } = useContext(AuthContext);
    const isMobile = useScreenSize();
    const location = useLocation();
    const navigate = useNavigate();
    const message = location.state?.message;

    // const defaultGameId = '75c0c41e-5d98-4d21-8e85-9c96ed240da1';

    console.log('Game Id from click is', location.state?.gameId);

    const [selectedGameId, setSelectedGameId] = useState(location.state?.gameId || '');

    const handleGameClick = (gameId) => {
        setSelectedGameId(gameId);
        localStorage.setItem('selectedGameTab', gameId);
    };

    useEffect(() => {
        const storedGameId = localStorage.getItem('selectedGameTab');
        if (storedGameId) {
            setSelectedGameId(storedGameId);
        }
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (accessToken) {
            navigate('/progames');
        }
    }, []);

    return (
        <>
            {message && <div className="alert alert-success">{message}</div>}
            <Cursor />
            <Preloader />
            <MainHeader1 toggleMenu={toggleMenu} />
            <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <div id="games">
                <GameArea2 callbackHandleClick={handleGameClick} />
            </div>
            <div id="tournament">
                {isMobile ? (
                    <TournamentListMobile
                        selectedGameId={selectedGameId}
                        gameHandles={{}}
                        landing={false}
                        pageType={'public'}
                        isScrollAvailable={true}
                    />
                ) : (
                    <ProTournamentsList2
                        selectedGameId={selectedGameId}
                        gameHandles={{}}
                        landing={false}
                        pageType={'public'}
                        isScrollAvailable={true}
                    />
                )}
            </div>
            <Footer2 />
            <ScrollToTop />
        </>
    );
};

export default GamesPage;
