import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import '../../Assets/sass/style.scss';
import 'magnific-popup';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import NextMatchCard from './nextMatchCard';
import ReactMarkdown from 'react-markdown';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import ProTournamentCard7 from './proTournamentCard7';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import TournamentCardMobile from '../MobileViewComp/tournamentCardMobile';
import TournamentRewardPopup from '../LandingPageComp/rewardsPopup';
import { mapGameToFolder } from '../../Utils/utils';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content form-group" onClick={(e) => e.stopPropagation()}>
                {children}
                <button className="modal-close" onClick={onClose}>
                    x
                </button>
            </div>
        </div>
    );
};

const ProTournamentDetails = ({ tournamentGamerHandle }) => {
    const [activeTab, setActiveTab] = useState('Overview');
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);
    const { gameId, tournamentId } = useParams();
    const [isRegistered, setIsRegistered] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [gamerHandle, setGamerHandle] = useState(null);
    const [newGamerHandle, setNewGamerHandle] = useState('');
    const [showGamerHandlePrompt, setShowGamerHandlePrompt] = useState(false);
    const [tournament, setTournament] = useState(null); // Store tournament data
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Fixed page size
    const [totalPages, setTotalPages] = useState(1);
    const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(false);
    const [leaderboardError, setLeaderboardError] = useState(null);
    const [nextMatches, setNextMatches] = useState([]); // Store the next tournaments
    const [nextMatchError, setNextMatchError] = useState(null);
    const isMobile = useScreenSize();
    const location = useLocation();

    const isTournamentDetailsPage = location.pathname.includes('/proTournament-details');

    // const { prize_pool_text: { top, bottom } } = response;
    //     const topText = response.prize_pool_text.top;
    //     const bottomText = response.prize_pool_text.bottom;

    // Updated getImage function to handle different game folders
    const getImage = (gameName, iconName) => {
        const folder = mapGameToFolder(gameName); // Get the folder based on the game name
        try {
            return require(`../../Assets/img/tournament/${folder}/${iconName}`);
        } catch (err) {
            console.error(`Image ${iconName} not found in ${folder}, using default.`);
            return require('../../Assets/img/tournament/Chess/1_1.png'); // Default fallback image
        }
    };

    // // Updated getImg function to handle different game folders
    // const getImg = (gameName, iconName) => {
    //     const folder = mapGameToFolder(gameName); // Get the folder based on the game name
    //     try {
    //         return require(`../../Assets/img/tournament/${folder}/${iconName}`);
    //     } catch (err) {
    //         console.error(`Image ${iconName} not found in ${folder}, using default.`);
    //         return require('../../Assets/img/tournament/Chess/1_1.png'); // Default fallback image
    //     }
    // };

    // Fetch tournament details on mount
    useEffect(() => {
        const fetchTournamentDetails = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/tournaments?tournament_id=${tournamentId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch tournament details');

                const data = await response.json();
                console.log('Fetched tournament details:', data);

                setTournament(data.tournament);
                setIsRegistered(data.tournament.is_registered);

                // if (isRegistered || data.tournament.cta_enabled === true) {
                //     setActiveTab('Leaderboard');
                // } else {
                //     setActiveTab('Overview');
                // }
            } catch (err) {
                console.error('Error fetching tournament:', err);
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTournamentDetails();
    }, [tournamentId, accessToken]);

    console.log('Tournament Status : ', tournament?.status);
    console.log('User Registration Status : ', tournament?.user_registered);

    const fetchLeaderboardData = async (page = 1) => {
        setIsLeaderboardLoading(true);
        setLeaderboardError(null);

        try {
            console.log(`Fetching leaderboard for tournament ID: ${tournamentId} on page ${page}`);
            const response = await fetchWithAuth(
                `${BASE_URL}/tournaments/${tournamentId}/leaderboard?page_size=${pageSize}&page=${page}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            const data = await response.json();
            console.log('Leaderboard API response:', data);

            if (response.ok) {
                setLeaderboardData(data.leaderboard || []);
                setTotalPages(data.total_pages || 1);
            } else {
                throw new Error(data.message || 'Failed to fetch leaderboard');
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            setLeaderboardError(error.message);
        } finally {
            setIsLeaderboardLoading(false);
        }
    };

    // Run this effect only once when the component mounts to check if the user is registered
    // useEffect(() => {
    //     if (tournament) {
    //         if (tournament.cta_enabled === true && isRegistered) {
    //             setActiveTab('Leaderboard');
    //         } else {
    //             setActiveTab('Overview');
    //         }
    //     }
    // }, [isRegistered, tournament]);
    console.log('isRegistered:', isRegistered);
    console.log('tournament.cta_enabled:', tournament?.cta_enabled);
    // useEffect(() => {
    //     if (isRegistered || tournament?.cta_enabled === true) {
    //         setActiveTab('Leaderboard');
    //     } else {
    //         setActiveTab('Overview');
    //     }
    // }, [isRegistered, tournament?.cta_enabled]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (isMobile) {
            document.querySelector('.mobile-tabs .tab-button.active')?.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
            });
        }
    };
    // Run this effect whenever activeTab changes to fetch leaderboard data
    useEffect(() => {
        if (activeTab === 'Leaderboard') {
            console.log('Fetching leaderboard because the tab is active...');
            fetchLeaderboardData();
        }
    }, [activeTab]);

    useEffect(() => {
        const fetchNextMatches = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/next_tournaments?tournament_id=${tournamentId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch next tournaments');

                const data = await response.json();
                console.log('Fetched next matches:', data);

                setNextMatches(data.tournaments || []);
            } catch (error) {
                console.error('Error fetching next matches:', error);
                setNextMatchError(error.message);
            }
        };

        fetchNextMatches();
    }, [tournamentId]);

    const renderLeaderboard = () => {
        if (isLeaderboardLoading) return <p>Loading leaderboard...</p>;
        if (leaderboardError) return <p>Error: {leaderboardError}</p>;
        if (leaderboardData.length === 0) return <p>No leaderboard data available.</p>;

        const handleRefreshLeaderboard = () => {
            fetchLeaderboardData(currentPage); // Fetch leaderboard data again
        };

        return (
            <>
            <TournamentRewardPopup tournament_id={tournamentId} />
                <div className="tdetails-table-container">
                    <table className="tdetails-table">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Player</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboardData.map((player, index) => (
                                <tr
                                    key={player.user_id || index}
                                    className={`${player.user_row ? "user-row-highlight" : ""} ${
                                        player.prize_row ? "prize-row-highlight" : ""
                                    }`}
                                >
                                    <td>{player.rank}</td>
                                    <td>
                                        {player.username} {player.user_row ? "(You)" : ""}
                                    </td>
                                    <td>
                                    {player.points}

                                    {/* Show ⚠️ Only If Fraud Review is Not Cleared */}
                                    {player.fraud_review && (
                                        <span className="under-review">
                                            ⚠️
                                            <span className="fraud-tooltip">{player.fraud_reason || "Under Review"}</span>
                                        </span>
                                    )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="refresh-button-container" style={{ textAlign: 'center', marginTop: '20px' }}>
                    <button className="refresh-button" onClick={handleRefreshLeaderboard}>
                        Refresh Leaderboard
                    </button>
                </div>
            </>
        );
    };

    const formatOverview = (overview) => {
        // Replace Unicode line separators with standard newlines
        let formattedOverview = overview.replace(/\u2028/g, '\n');

        // Replace single newline characters with double newlines for markdown
        formattedOverview = formattedOverview.replace(/\n/g, '\n\n');

        return formattedOverview;
    };

    const renderOverview = () => {
        if (!tournament || !tournament.overview) return <p>No overview available.</p>;

        // Clean and split the overview content based on line breaks and colons
        const overviewSections = tournament.overview
            .split('\n') // Split by newlines to get each section
            .filter((line) => line.trim()) // Remove any empty lines
            .map((line) => line.split(':')); // Split each line by the first colon

        return <ReactMarkdown>{formatOverview(tournament.overview)}</ReactMarkdown>;
    };

    const renderScoringCriteria = () => {
        if (!tournament || !tournament.scoring_criteria || Object.keys(tournament.scoring_criteria).length === 0) {
            return <p>No scoring criteria available.</p>;
        }
        const { scoring_text } = tournament || {};
        const topText = scoring_text?.top || 'Default top text'; // Fallback in case top is missing
        const bottomText = scoring_text?.bottom || 'Default bottom text';

        // Parse the scoring criteria JSON
        let scoringData;
        try {
            scoringData = tournament.scoring_criteria;
        } catch (error) {
            console.error('Error parsing scoring criteria:', error);
            return <p>Error loading scoring criteria.</p>;
        }

        // Get the first key (e.g., "Chess" or "Clash_of_Clans") and its criteria list
        const criteriaKey = Object.keys(scoringData)[0];
        const criteriaList = scoringData[criteriaKey] || [];

        // Determine the scoring type
        const isModeBased = criteriaList.length > 0 && criteriaList[0].mode !== undefined; // Chess
        const isTrophyBased = criteriaList.length > 0 && criteriaList[0].min_trophies !== undefined; // Clash of Clans

        return (
            <div className="scoring-table-container">
                <p>{topText}</p>

                {isModeBased ? (
                    <table className="scoring-table">
                        <thead>
                            <tr>
                                <th>Result</th>
                                <th>Bullet 1m</th>
                                <th>Blitz 5m</th>
                                <th>Rapid 10m</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Win</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'bullet')?.win || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'blitz')?.win || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'rapid')?.win || '-'}</td>
                            </tr>
                            <tr>
                                <td>Draw</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'bullet')?.draw || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'blitz')?.draw || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'rapid')?.draw || '-'}</td>
                            </tr>
                            <tr>
                                <td>Loss</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'bullet')?.loss || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'blitz')?.loss || '-'}</td>
                                <td>{criteriaList.find((mode) => mode.mode === 'rapid')?.loss || '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : isTrophyBased ? (
                    <table className="scoring-table">
                        <thead>
                            <tr>
                                <th>Trophy Range</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {criteriaList.map((trophy, index) => (
                                <tr key={index}>
                                    <td>{trophy.trophies_str || `${trophy.min_trophies} - ${trophy.max_trophies}`}</td>
                                    <td>{trophy.points}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : null}

                <p>{bottomText}</p>
            </div>
        );
    };

const renderPrizePoolDistribution = () => {
    if (!tournament || !tournament.max_prize_pool_distribution) {
        return <p>No prize pool distribution available.</p>;
    }

    // Parse the max_prize_pool_distribution JSON
    let distributionData;
    try {
        distributionData = JSON.parse(tournament.max_prize_pool_distribution);
    } catch (error) {
        console.error("Error parsing prize pool distribution:", error);
        return <p>Error loading prize pool distribution.</p>;
    }

    // Extract prize pool texts
    const { prize_pool_text } = tournament || {};
    const topText = prize_pool_text?.top || "Prize Pool Distribution";
    const bottomText = prize_pool_text?.bottom || "";

    // Check if it's a BGMI tournament (Kill-based reward system)
    const isBGMI = tournament?.name?.toLowerCase().includes("bgmi");

    if (isBGMI) {
        // const bgmiDistribution = distributionData[0].prize_pool_distribution; // Assuming BGMI is a top-level key

        let bgmiDistribution;
        try {
            bgmiDistribution = JSON.parse(tournament.current_prize_pool_distribution);
        } catch (error) {
            console.error("Error parsing BGMI prize pool distribution:", error);
            return <p>Error loading BGMI prize pool distribution.</p>;
        }

        return (
            <div className="prize-pool-container">
                <p className="prize-pool-header">{topText}</p>
                <div className="bgmi-prize-table">
                    <table className="prize-pool-table">
                        <thead>
                            <tr>
                                <th>Kills</th>
                                <th>Reward (ACash)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(bgmiDistribution).map(([kills, reward], index) => (
                                <tr key={index}>
                                    <td>{kills}</td>
                                    <td>{reward}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <p className="prize-pool-footer">{bottomText}</p>
            </div>
        );
    }

    // For non-BGMI tournaments (Rank-based prize distribution)
    const maxPlayers = distributionData.map((item) => item.max_players);
    const prizeDistributions = distributionData.map((item) => item.prize_pool_distribution);

    // Collect all unique ranks (including ranges)
    const uniqueRanks = new Set();
    prizeDistributions.forEach((distribution) => {
        Object.keys(distribution).forEach((key) => uniqueRanks.add(key));
    });

    // Convert the unique ranks set to a sorted array
    const sortedRanks = Array.from(uniqueRanks).sort((a, b) => {
        const parseRank = (rank) => (rank.includes("-") ? parseInt(rank.split("-")[0], 10) : parseInt(rank, 10));
        return parseRank(a) - parseRank(b);
    });

    // Determine if scrolling is needed based on prize pool distribution length (number of rows)
    const needsScrolling = sortedRanks.length > 3;

    return (
        <div className="prize-pool-container">
            <p className="prize-pool-header">{topText}</p>

            <div className={`prize-pool-table-wrapper ${needsScrolling ? "scrollable" : ""}`}>
                <table className="prize-pool-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            {maxPlayers.map((players, index) => (
                                <th key={index}>
                                    {players} <br /> <span className="players-label">Players</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRanks.map((rankKey, rankIndex) => (
                            <tr key={rankIndex}>
                                <td>{rankKey}</td>
                                {prizeDistributions.map((distribution, distIndex) => (
                                    <td key={distIndex}>
                                        {distribution[rankKey] !== undefined ? distribution[rankKey] : "-"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr className="total-row">
                            <td>Total</td>
                            {distributionData.map((item, index) => (
                                <td key={index}>{item.max_prize_pool}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            <p className="prize-pool-footer">{bottomText}</p>
        </div>
    );
};



    const renderRules = () => {
        if (!tournament || !tournament.rules) return <p>No rules available.</p>;

        // Split the rules text into sections
        const rulesSections = tournament.rules.split('\n').filter((line) => line.trim() !== '');
        return <ReactMarkdown>{formatOverview(tournament.rules)}</ReactMarkdown>;
    };

    // Function to render content based on the active tab
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Overview':
                return renderOverview();
            case 'Leaderboard':
                return renderLeaderboard();
            case 'Scoring':
                return renderScoringCriteria();
            case 'Prize Pool':
                return renderPrizePoolDistribution();
            case 'Rules':
                return renderRules();
            default:
                return null;
        }
    };

    // Handle loading and error states
    if (isLoading) return <div>Loading tournament details...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!tournament) return <div>No tournament details available.</div>;

    return (
        <>
            <section
                className="tournament-details-page space-top space-extra2-bottom"
                style={{ marginTop: '50px', backgroundColor: 'black' }}
            >
                <div className="container">
                    <div className="row gx-40">
                        <div className="col-12 active mb-60">
                            {isMobile ? (
                                <TournamentCardMobile
                                    key={tournament.tournament_id}
                                    gameId={tournament.game_id}
                                    gamerHandle={tournamentGamerHandle}
                                    tournamentId={tournament.tournament_id}
                                    // tournamentImg1={GameVS1} // Placeholder image
                                    // tournamentImg2={GameVS1} // Placeholder image
                                    tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                    // topText={topText}
                                    // bottomText={bottomText}
                                    gameVSImg={GameVS1}
                                    cardBg={TournamentCard2Bg}
                                    subtitle1={tournament.mode}
                                    title1={tournament.name}
                                    subtitle2={tournament.duration}
                                    title2={tournament.prize_pool}
                                    flexible={["Mega Tournament", "Mega Event"].includes(tournament.tournament_type)}
                                    // time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                                    endTime={tournament.end_time}
                                    startTime={tournament.start_time}
                                    // date={new Date(tournament.start_time * 1000).toLocaleDateString()}
                                    status={tournament.status}
                                    currentPlayers={tournament.current_players}
                                    maxPlayers={tournament.max_players}
                                    detailsLink={`/proTournament-details/${tournament.game_id}/${tournament.tournament_id}`}
                                    ctaText={tournament.cta_text}
                                    ctaEnabled={tournament.cta_enabled}
                                    entryFee={tournament.entry_fee}
                                    userRegistrationAllowed={tournament.user_registration_allowed}
                                    winRate={tournament.win_rate}
                                    prizePoolType={tournament.prize_pool_type}
                                    setParentActiveTab={isTournamentDetailsPage ? setActiveTab : null}
                                />
                            ) : (
                                <ProTournamentCard7
                                    key={tournament.tournament_id}
                                    gameId={tournament.game_id}
                                    gamerHandle={tournamentGamerHandle}
                                    tournamentId={tournament.tournament_id}
                                    // tournamentImg1={GameVS1} // Placeholder image
                                    // tournamentImg2={GameVS1} // Placeholder image
                                    tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                    gameVSImg={GameVS1}
                                    cardBg={TournamentCard2Bg}
                                    subtitle1={tournament.mode}
                                    title1={tournament.name}
                                    subtitle2={tournament.duration}
                                    title2={tournament.prize_pool}
                                    flexible={["Mega Tournament", "Mega Event"].includes(tournament.tournament_type)}
                                    // time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                                    endTime={tournament.end_time}
                                    startTime={tournament.start_time}
                                    // date={new Date(tournament.start_time * 1000).toLocaleDateString()}
                                    status={tournament.status}
                                    currentPlayers={tournament.current_players}
                                    maxPlayers={tournament.max_players}
                                    detailsLink={`/proTournament-details/${tournament.game_id}/${tournament.tournament_id}`}
                                    ctaText={tournament.cta_text}
                                    ctaEnabled={tournament.cta_enabled}
                                    entryFee={tournament.entry_fee}
                                    userRegistrationAllowed={tournament.user_registration_allowed}
                                    setParentActiveTab={isTournamentDetailsPage ? setActiveTab : null}
                                />
                            )}
                        </div>
                        <div className="col-lg-8">
                            <div className="page-single tournament-details-wrap">
                                <div className="page-content">
                                    {/* <h2 className="sec-title page-title h3">ESC Season 8 – Opening Match</h2> */}
                                    {/* Tab Navigation */}
                                    <div className="tab-outer-border">
                                        <div className="tab-navigation">
                                            {['Overview', 'Leaderboard', 'Scoring', 'Prize Pool', 'Rules'].map(
                                                (tab) => (
                                                    <button
                                                        key={tab}
                                                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(tab)}
                                                    >
                                                        {tab}
                                                    </button>
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    {/* Render the content based on active tab */}
                                    <div className="">
                                        <div className="tab-content">{renderTabContent()}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="th-comments-wrap ">
                        <h3 className="blog-inner-title"><i className="far fa-comments"></i> Comments (03)</h3>
                        <ul className="comment-list">
                            <li className="th-comment-item">
                                <div className="th-post-comment">
                                    <div className="comment-avater">
                                        <img src="assets/img/blog/comment-author-1.jpg" alt="Comment-Author"/>
                                    </div>
                                    <div className="comment-content">
                                        <h3 className="name">Adam Jhon</h3>
                                        <span className="commented-on">25Aug, 2024 08:56pm</span>
                                        <p className="text">Your health and well-being are our top priorities. We take the time to listen to your concerns, answer your questions.</p>
                                        <div className="reply_and_edit">
                                            <a href="blog-details.html" className="reply-btn"><i className="fas fa-reply"></i>Reply</a>
                                        </div>
                                    </div>
                                </div>
                                <ul className="children">
                                    <li className="th-comment-item">
                                        <div className="th-post-comment">
                                            <div className="comment-avater">
                                                <img src="assets/img/blog/comment-author-2.jpg" alt="Comment-Author"/>
                                            </div>
                                            <div className="comment-content">
                                                <h3 className="name">Jhon Abraham</h3>
                                                <span className="commented-on">25July, 2024 10:56pm</span>
                                                <p className="text">We understand that every patient is unique, and their healthcare needs may vary. That's why we create individualized.</p>
                                                <div className="reply_and_edit">
                                                    <a href="blog-details.html" className="reply-btn"><i className="fas fa-reply"></i>Reply</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="th-comment-item">
                                <div className="th-post-comment">
                                    <div className="comment-avater">
                                        <img src="assets/img/blog/comment-author-3.jpg" alt="Comment-Author"/>
                                    </div>
                                    <div className="comment-content">
                                        <h3 className="name">Anadi Juila</h3>
                                        <span className="commented-on">15 Jan, 2024 08:56pm</span>
                                        <p className="text">Our clinic is strategically located for easy access, ensuring that you can reach us conveniently from various parts of the community.</p>
                                        <div className="reply_and_edit">
                                            <a href="blog-details.html" className="reply-btn"><i className="fas fa-reply"></i>Reply</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                  </div>  
                    <div className="th-comment-form ">
                        <div className="form-title">
                            <h3 className="blog-inner-title mb-2"><i className="fa-solid fa-reply"></i> Leave a Comment</h3>
                            <p className="form-text">Your email address will not be published. Required fields are marked *</p>
                        </div>
                        <div className="row">
                            <div className="col-md-6 form-group style-border">
                                <input type="text" placeholder="Your Name*" className="form-control"/>
                                <i className="far fa-user"></i>
                            </div>
                            <div className="col-md-6 form-group style-border">
                                <input type="text" placeholder="Your Email*" className="form-control"/>
                                <i className="far fa-envelope"></i>
                            </div>
                            <div className="col-12 form-group style-border">
                                <input type="text" placeholder="Website" className="form-control"/>
                                <i className="far fa-globe"></i>
                            </div>
                            <div className="col-12 form-group style-border">
                                <textarea placeholder="Write a Comment*" className="form-control"></textarea>
                                <i className="far fa-pencil"></i>
                            </div>
                            <div className="col-12 form-group mb-0">
                                <button className="th-btn">SEND MESSAGE <i className="far fa-arrow-right ms-2"></i></button>
                            </div>
                        </div>
                    </div> */}
                        </div>
                        <div className="col-lg-4">
                            <h3 className="widget_title">Next Match</h3>
                            <aside className="sidebar-area next-match-section">
                                {nextMatchError ? (
                                    <p>Error: {nextMatchError}</p>
                                ) : nextMatches.length === 0 ? (
                                    <p>No upcoming matches available.</p>
                                ) : (
                                    nextMatches.map((match) => (
                                        <NextMatchCard
                                            tournamentId={match.tournament_id}
                                            gameId={match.game_id}
                                            gamerHandle={tournamentGamerHandle}
                                            imageSrc={getImage(match.name, match.image_icon)}
                                            status="Upcoming" // Setting status explicitly or based on match data
                                            // imageSrc={CharacterImage} // Default image if none provided
                                            // imageSrc={match.image_icon}
                                            title={match.name} // Tournament name
                                            duration={`${match.duration} HR`} // Duration in hours
                                            progress="50%" // Placeholder or calculated value
                                            startTime={match.start_time}
                                            endTime={match.end_time}
                                            // timeLeftLabel={formatTimeLeft(match.start_time, match.end_time).label} // Time label
                                            // timeLeft={formatTimeLeft(match.start_time, match.end_time).time} // Ti
                                            matchType={match.tournament_type} // Tournament type
                                            mode={match.mode} // Tournament mode
                                            prize={match.prize_pool} // Prize pool amount
                                            rank={match.user_rank || 'N/A'} // User rank or default if unregistered
                                            players={`${match.current_players} / ${match.max_players}`} // Current vs max players
                                            detailsLink={`/proTournament-details/${gameId}/${match.tournament_id}`}
                                            ctaText={match.cta_text}
                                            ctaEnabled={match.cta_enabled}
                                        />
                                    ))
                                )}
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProTournamentDetails;
