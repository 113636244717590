import React, { useEffect, useState, useRef } from 'react';
import '../../App.css';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import BameLogo from '../../Assets/img/logo.svg';
import MobileMenu from '../LandingPageComp/mobileMenu';
import '../../Components/UserProfilePageComp/PartnerWithUs/partnerCheckList.css';
import '../../Components/LandingPageComp/popUp.css';
import { useNavigate } from 'react-router-dom';

const MainHeader1 = ({ toggleMenu }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('India');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCountryChange = (country) => {
        setSelectedCountry(country); // Update the selected country
        setIsDropdownOpen(false); // Close the dropdown
    };

    const scrollToSection = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('how-it-works');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const togglePartnerWithUs = () => {
        navigate('/partner-with-us');
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Toggle category menu visibility
    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        console.log('Button is clicked');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // useEffect(() => {
    //     // Define the thmobilemenu plugin within the component
    //     (function ($) {
    //       $.fn.thmobilemenu = function (options) {
    //         var opt = $.extend(
    //           {
    //             menuToggleBtn: ".th-menu-toggle",
    //             bodyToggleClass: "th-body-visible",
    //             subMenuClass: "th-submenu",
    //             subMenuParent: "menu-item-has-children",
    //             thSubMenuParent: "th-item-has-children",
    //             subMenuParentToggle: "th-active",
    //             meanExpandClass: "th-mean-expand",
    //             appendElement: '<span class="th-mean-expand"></span>',
    //             subMenuToggleClass: "th-open",
    //             toggleSpeed: 400,
    //           },
    //           options
    //         );

    //         return this.each(function () {
    //           var menu = $(this); // Select menu

    //           // Menu Show & Hide
    //           function menuToggle() {
    //             menu.toggleClass(opt.bodyToggleClass);

    //             // Collapse submenu on menu hide or show
    //             var subMenu = "." + opt.subMenuClass;
    //             $(subMenu).each(function () {
    //               if ($(this).hasClass(opt.subMenuToggleClass)) {
    //                 $(this).removeClass(opt.subMenuToggleClass);
    //                 $(this).css("display", "none");
    //                 $(this).parent().removeClass(opt.subMenuParentToggle);
    //               }
    //             });
    //           }

    //           // Class Set Up for every submenu
    //           menu.find("." + opt.subMenuParent).each(function () {
    //             var submenu = $(this).find("ul");
    //             submenu.addClass(opt.subMenuClass);
    //             submenu.css("display", "none");
    //             $(this).addClass(opt.subMenuParent);
    //             $(this).addClass(opt.thSubMenuParent); // Add th-item-has-children class
    //             $(this).children("a").append(opt.appendElement);
    //           });

    //           // Toggle Submenu
    //           function toggleDropDown($element) {
    //             var submenu = $element.children("ul");
    //             if (submenu.length > 0) {
    //               $element.toggleClass(opt.subMenuParentToggle);
    //               submenu.slideToggle(opt.toggleSpeed);
    //               submenu.toggleClass(opt.subMenuToggleClass);
    //             }
    //           }

    //           // Submenu toggle Button
    //           var itemHasChildren = "." + opt.thSubMenuParent + " > a";
    //           $(itemHasChildren).each(function () {
    //             $(this).on("click", function (e) {
    //               e.preventDefault();
    //               toggleDropDown($(this).parent());
    //             });
    //           });

    //           // Menu Show & Hide On Toggle Btn click
    //           $(opt.menuToggleBtn).each(function () {
    //             $(this).on("click", function () {
    //               menuToggle();
    //             });
    //           });

    //           // Hide Menu On outside click
    //           menu.on("click", function (e) {
    //             e.stopPropagation();
    //             menuToggle();
    //           });

    //           // Stop Hide full menu on menu click
    //           menu.find("div").on("click", function (e) {
    //             e.stopPropagation();
    //           });
    //         });
    //       };
    //     })($);

    //     // Initialize the plugin on the menu wrapper
    //     $(".th-menu-wrapper").thmobilemenu({
    //       menuToggleBtn: ".th-menu-toggle",
    //       bodyToggleClass: "th-body-visible",
    //       subMenuClass: "th-submenu",
    //       subMenuParent: "menu-item-has-children",
    //       thSubMenuParent: "th-item-has-children",
    //       subMenuParentToggle: "th-active",
    //       meanExpandClass: "th-mean-expand",
    //       appendElement: '<span class="th-mean-expand"></span>',
    //       subMenuToggleClass: "th-open",
    //       toggleSpeed: 400,
    //     });
    //   }, []); // Run once when the component mounts

    return (
        <>
            <header className={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`} style={{ color: 'white' }}>
                {/* Header Top */}
                {/* {isHomePage && (
                <div className="header-top">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                            <div className="col-auto d-none d-lg-block">
                                <p className="header-notice"></p>
                                <div className="header-links">
                                    <ul>
                                        <li>
                                            <div className="header-notice">
                                                Hello, <span style={{ color: '#45F882' }}>Champions! </span>
                                                Games On!
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dropdown-link" ref={dropdownRef}>
                                                <a
                                                    className="dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="dropdownMenuLink1"
                                                    onClick={toggleDropdown}
                                                    aria-expanded={isDropdownOpen}
                                                >
                                                    <i className="fa-solid fa-globe"></i> {selectedCountry}
                                                </a>
                                                {isDropdownOpen && (
                                                    <ul
                                                        className="dropdown-menu show"
                                                        style={{
                                                            textAlign: 'left',
                                                            width: '250px',
                                                        }}
                                                        aria-labelledby="dropdownMenuLink1"
                                                    >
                                                        {[
                                                            'Brazil',
                                                            'United States',
                                                            'South Korea',
                                                            'Philippines',
                                                            'Vietnam',
                                                            'Indonesia',
                                                            'United Kingdom',
                                                            'United Arab Emirates (UAE)',
                                                            'Saudi Arabia',
                                                        ].map((country) => (
                                                            <li key={country}>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                    onClick={() => handleCountryChange(country)}
                                                                >
                                                                    {country}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="header-links">
                                    <ul className="th-social style-mask">
                                        <li>
                                            <a className="discord" href="https://discord.gg/VagU5uMCnW">
                                                <i className="fab fa-discord"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" href="https://www.instagram.com/agongamescom/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="x-twitter" href="https://x.com/agongamescom">
                                                <i className="fab fa-x-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="youtube" href="https://www.youtube.com/@Agongamescom">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  )} */}
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container">
                            <div
                                className="row align-items-center justify-content-between"
                                style={{ flexWrap: 'nowrap' }}
                            >
                                {/* Logo Section */}
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/">
                                            <span data-mask-src={BameLogo} className="logo-mask"></span>
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>

                                {/* Main Menu Section */}
                                <div className="col">
                                    <nav className="main-menu d-none d-lg-flex" style={{ marginLeft: '100px' }}>
                                        <ul className="d-flex">
                                            <li>
                                                <Link to="/">HOME</Link>
                                            </li>
                                            <li>
                                                <Link to="/games">TOURNAMENTS</Link>
                                            </li>
                                            <li>
                                                <Link to="/shop">SHOP</Link>
                                                {/* className="menu-item-has-children" */}
                                                {/* <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/cart">Cart</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/checkout">Checkout</Link>
                                                    </li>
                                                </ul> */}
                                            </li>
                                            <li>
                                                <Link to="/leaderboard">LEADERBOARD</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/agon-guilds">AGON GUILD</Link>
                                            </li> */}
                                            <li>
                                                <a href="#" onClick={togglePartnerWithUs}>
                                                    PARTNER
                                                </a>
                                            </li>
                                            {/*
                                            <li>
                                                <Link to="/">AGON-PARTNERS</Link>
                                            </li>
                                            */}
                                        </ul>
                                    </nav>
                                </div>

                                {/* Right Section - Toggle and Login */}
                                <div className="col-auto d-flex align-items-center" style={{ padding: 0 }}>
                                    <button
                                        type="button"
                                        className="simple-icon sideMenuInfo header-button me-3"
                                        onClick={toggleMobileMenu}
                                    >
                                        <i
                                            className="fa-solid fa-bars"
                                            style={{
                                                fontSize: 'x-large',
                                            }}
                                        ></i>
                                    </button>
                                    <Link
                                        to="/login"
                                        className="th-btn  button-login"
                                        style={{
                                            color: 'black',
                                            maxWidth: '120px',
                                        }}
                                    >
                                        Login
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Mobile Menu Sliding Effect */}
            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
        </>
    );
};

export default MainHeader1;
