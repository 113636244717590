import React, { useState, useEffect, useContext } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './SideNav.css';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { BASE_URL, fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';

const SideNavbar = ({ isOpen, handleMenuClick }) => {
    const { logout, accessToken } = useContext(AuthContext);
    const [profile, setProfile] = useState({
        username: '', // This will be set from display_name
        proficiency: '',
        image: null,
    });
    const [error, setError] = useState(null);
    const [activeMenu, setActiveMenu] = useState(null);

    const navigate = useNavigate();

    // Fetch user profile from API
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();

                if (data.code === 200) {
                    const { display_name, AgonXP_tier } = data.user_data;
                    setProfile((prevProfile) => ({
                        ...prevProfile,
                        username: display_name,
                        proficiency: AgonXP_tier,
                    }));
                } else {
                    throw new Error('Failed to fetch profile data');
                }
            } catch (e) {
                console.error(e);
                setError('Error fetching profile data');
            }
        };

        fetchProfile();
    }, []);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProfile({
                ...profile,
                image: URL.createObjectURL(e.target.files[0]),
            });
        }
    };

    const handleEditProfile = () => {
        navigate(`/my-dashboard`);
    };

    const handleLogoutClick = () => {
        const confirmed = window.confirm('Are you sure you want to logout?');
        if (confirmed) {
            logout();
        }
    };

    const menuOptions = [
        { icon: <i class="fa-solid fa-trophy menu-icon"></i>, label: 'My Tournaments', route: '/my-tournaments' },
        {
            icon: <i class="fa-solid fa-cart-arrow-down menu-icon"></i>,
            label: 'My Vouchers',
            route: '/proShop?tab=redeemed',
        },
        { icon: <i class="fa-solid fa-gift menu-icon"></i>, label: 'My Rewards', route: '/my-rewards' },
        { icon: <i class="fa-solid fa-crown menu-icon"></i>, label: 'My Pass', route: '/premium-account' },
        { icon: <i class="fa-solid fa-wallet menu-icon"></i>, label: 'My Wallet', route: '/my-wallet' },
        { icon: <i class="fa-solid fa-phone menu-icon"></i>, label: 'Contact Us', route: '/contact' },
        { icon: <i class="fa-solid fa-sign-out-alt menu-icon logout-icon"></i>, label: 'Logout', route: '/logout' },
    ];

    // const handleMenuClick = (item) => {
    //     if (activeMenu === item) {
    //         toggleSideNavbar(); // Close the sidebar if the same menu is clicked
    //     } else {
    //         setActiveMenu(item);
    //         navigate(item.route);
    //     }
    // };

    return (
        <div className="side-navbar-container" style={{ backgroundColor: 'black' }}>
            <div className={`side-navbar ${isOpen ? 'open' : 'closed'}`}>
                <div className="profile-section" style={{ cursor: 'pointer' }} onClick={handleEditProfile}>
                    {/* Commented out the image */}
                    {/*
                    <label htmlFor="upload" className="profile-picture2">
                        <img src={profile.image || 'https://via.placeholder.com/80'} alt="Profile" />
                    </label>
                    */}
                    <div className="profile-info">
                        <h4>{profile.username || 'User'}</h4>
                        <p>{profile.proficiency}</p>
                    </div>
                    <FaChevronRight className="edit-icon" />
                </div>

                <div className="menu-options">
                    {menuOptions.map((item, index) => (
                        <div
                            className="menu-item"
                            key={index}
                            onClick={item.label === 'Logout' ? handleLogoutClick : () => handleMenuClick(item.route)}
                        >
                            {item.icon}
                            <span>{item.label} </span>
                        </div>
                    ))}
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

SideNavbar.propTypes = {
    gameId: PropTypes.string.isRequired,
};

export default SideNavbar;
