import React, { useEffect, useState } from 'react';
import '../../CustomCss/customStyles.css';
import $ from 'jquery';
// import Swiper from 'swiper';
// import Swiper from '../../Assets/js/swiper-bundle.min';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import Feature1_4 from '../../Assets/img/feature/feature1-4.png';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import Card1 from '../Common/Card1';

const GameArea2 = ({ callbackHandleClick }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setGames(data.games);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

useEffect(() => {
    // Initialize Swiper slider
    const gameSwiper = new Swiper('.game-slider', {
        slidesPerView: '1.5',
        spaceBetween: 24,
        loop: true,
        speed: 1000,
        autoplay: { delay: 6000, disableOnInteraction: false },
        navigation: {
            nextEl: '.game-slider-next',
            prevEl: '.game-slider-prev',
        },
        pagination: {
            el: '.game-slider-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return `<span class="${className}" aria-label="Go to Slide ${index + 1}"></span>`;
            },
        },
        breakpoints: {
            576: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 3 },
        },
    });

        // Add animations
        $('[data-ani]').each(function () {
            const animationName = $(this).data('ani');
            $(this).addClass(animationName);
        });

        $('[data-ani-delay]').each(function () {
            const delayTime = $(this).data('ani-delay');
            $(this).css('animation-delay', delayTime);
        });

        // External navigation handlers
        $('[data-slider-prev], [data-slider-next]').on('click', function () {
            const sliderSelector = $(this).data('slider-prev') || $(this).data('slider-next');
            const targetSlider = $(sliderSelector);

            if (targetSlider.length) {
                const swiperInstance = targetSlider[0].swiper;
                if (swiperInstance) {
                    if ($(this).data('slider-prev')) {
                        swiperInstance.slidePrev();
                    } else {
                        swiperInstance.slideNext();
                    }
                }
            }
        });

        // return () => {
        //   // Cleanup Swiper instance if necessary
        //   if (swiper) swiper.destroy();
        // };
    }, [games]); // Re-run slider initialization when games change

    const handleGameClick = (gameId) => {
        console.log('Navigating to game ID', gameId);
        callbackHandleClick(gameId);
        // navigate(`/games`, { state: { gameId } }); // Navigate to GamesPage with the selected game_id
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    // Variations for featureImage and gameDescription
    const featureImages = [Feature1_1, Feature1_3, Feature1_2, Feature1_4];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    return (
        <>
            <section
                className="feature-game-area-1 bg-black"
                style={{ backgroundColor: 'black', paddingTop: '120px', paddingBottom: '30px ' }}
            >
                <div className="container">
                    <div className="slider-area feature-game-slider1">
                        <div
                            className="swiper game-slider"
                            id="featureGameSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
                        >
                            <span className="sub-title" style={{ marginTop: '30px', textAlign: 'center' }}>
                                # Epic Clash
                            </span>
                            <h2 className="sec-title" style={{ textAlign: 'center', fontWeight: '700' }}>
                                Game On, Level Up, Win Big <span className="text-theme">!</span>
                            </h2>
                            <div className="swiper-wrapper">
                                {games.map((game, index) => (
                                    <div
                                        className="swiper-slide"
                                        key={game.game_id}
                                        onClick={() => handleGameClick(game.game_id)}
                                    >
                                        <Card1
                                            gameId={game.game_id}
                                            // featureCardBg={FeatureCardBg}
                                            featureImage={featureImages[index % featureImages.length]} // Cycle through images
                                            gameTitle={game.name}
                                            gameDescription={gameDescriptions[index % gameDescriptions.length]} // Cycle through descriptions
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-pagination slider-pagination"></div>
                        </div>
                        <button
                            data-slider-prev="#featureGameSlider1"
                            className="slider-arrow style3 slider-prev"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <i className="fas fa-angle-left"></i>
                        </button>
                        <button
                            data-slider-next="#featureGameSlider1"
                            className="slider-arrow style3 slider-next"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GameArea2;
