import React, { useEffect } from 'react';
// import * as serviceWorker from '../src/PWA/serviceWorker';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup'; // Import Magnific Popup JS
// import reportWebVitals from './reportWebVitals';
import posthog from 'posthog-js';

// Expose jQuery globally if needed by plugins
window.$ = window.jQuery = $;

const AppWrapper = () => {
    // Push Notifications: Request permission and subscribe
    // const requestNotificationPermission = async () => {
    //     if ('Notification' in window && navigator.serviceWorker) {
    //         const permission = await Notification.requestPermission();

    //         if (permission === 'granted') {
    //             console.log('Notification permission granted.');

    //             const registration = await navigator.serviceWorker.ready;
    //             const subscription = await registration.pushManager.subscribe({
    //                 userVisibleOnly: true,
    //                 applicationServerKey: '<YOUR_PUBLIC_VAPID_KEY>',
    //             });
    //             console.log('Push subscription:', subscription);
    //             // Send subscription to your server to store it
    //         }
    //     }
    // };

    // Background Sync Registration
    // const registerBackgroundSync = async () => {
    //     if ('serviceWorker' in navigator && 'SyncManager' in window) {
    //         try {
    //             const registration = await navigator.serviceWorker.ready;
    //             await registration.sync.register('sync-data');
    //             console.log('Background sync registered');
    //         } catch (error) {
    //             console.error('Background sync registration failed', error);
    //         }
    //     }
    // };

    const registerPostHog = () => {
        if (window.location.hostname === 'localhost') {
            return;
        }
        posthog.init('phc_2Ft5DAf8DfVPVnlkJFi03IgVpOWJ5Ilg0qvDOW12rxx', {
            api_host: 'https://us.i.posthog.com',
            person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        });
    };

    useEffect(() => {
        // requestNotificationPermission();
        // registerBackgroundSync();
        registerPostHog();
    }, []);

    return (
        <div>
            <App />
        </div>
    );
};

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>,
);

// Register the service worker to make the app a PWA
// serviceWorker.register();

// Log web vitals for performance
// reportWebVitals(console.log);
