import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import TournamentDetails from '../../Components/TournamentDetailsPageComp/tournamentDetails';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import MainHeader1 from '../../Components/Common/MainHeader1';
import BottomNavbar from '../../Components/Common/BottomNavbar';

const TournamentDetailsPage = () => {
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (accessToken) {
            // If token exists, navigate to /home
            navigate('/progames');
        }
        localStorage.setItem('prevPage', window.location.pathname);
        // If no token, stay on /gamespage (no need to do anything)
    }, []); // Add navigate as a dependency for useEffect

    return (
        <>
            <Cursor />
            <Preloader />
            <MainHeader1 toggleMenu={toggleMenu} />
            <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            {/* <SimpleHeader2/> */}
            <SideMenu2 />
            {/* <ColorSwitcher/> */}
            <TournamentDetails />
            {/* <BottomNavbar /> */}
            <Footer2 />
            <ScrollToTop />
        </>
    );
};

export default TournamentDetailsPage;
