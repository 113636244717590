import React, { useEffect, useState, useContext } from 'react';
import Guild from '../../Components/UserProfilePageComp/Guild/Guild';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import GuildLeaderboard from '../../Components/UserProfilePageComp/GuildLeaderboard/GuildLeaderboard';
import { AuthContext } from '../../Utils/Contexts/authContext';
import MainHeader1 from '../../Components/Common/MainHeader1';
import SideMenu2 from '../../Components/Common/SideMenu2';

const ProUserGuildPage = () => {
    const { accessToken } = useContext(AuthContext);
    const [ambassador, setAmbassador] = useState(localStorage.getItem('ambassador') || '');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'ambassador') {
                setAmbassador(event.newValue);
            }
        };

        window.addEventListener('ambassador', handleStorageChange);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('ambassador', handleStorageChange);
        };
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <Cursor />
            <Preloader />
            {!accessToken ? <MainHeader1 toggleMenu={toggleMenu} /> : <SimpleHeader3 />}{' '}
            <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            {ambassador != 0 ? <GuildLeaderboard /> : <Guild />}
            <Footer2 />
            <ScrollToTop />
        </>
    );
};

export default ProUserGuildPage;
