import React, { useEffect, useState } from 'react';
import { formatDate, formatTimeLeft } from '../../Utils/timerUtils'; // Import utility functions

const Timer = ({ startTime, endTime }) => {
    const [timeData, setTimeData] = useState({ label: '', time: '' });

    useEffect(() => {
        setTimeData(formatTimeLeft(startTime, endTime));
        const interval = setInterval(() => {
            setTimeData(formatTimeLeft(startTime, endTime));
        }, 1000);

        return () => clearInterval(interval);
    }, [startTime, endTime]);

    return (
        <div className="timer">
            <h6 className="timer-label" style={{ color: '#45F882', textAlign: 'center' }}>
                {timeData.label}
            </h6>
            <span className="timer-value">{timeData.time}</span>
            {timeData.label === 'ENDED AT' && (
                <p className="tournament-card-date">
                    {/* {new Date(startTime * 1000).toLocaleDateString()} */}
                    {formatDate(startTime)}
                </p>
            )}
        </div>
    );
};

export default Timer;
