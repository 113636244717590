import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import 'jquery-ui/ui/widgets/slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import MainHeader1 from '../../Components/Common/MainHeader1';
import StatsTimer from '../../Components/LandingPageComp/statsTimer';
import HeroSection from '../../Components/LandingPageComp/heroSection';
import GameArea from '../../Components/LandingPageComp/gameArea1';
import HowItWorks from '../../Components/LandingPageComp/howItWorks';
import AboutUs from '../../Components/LandingPageComp/aboutUs';
import JoinUs from '../../Components/LandingPageComp/joinUs';
import GamersShop from '../../Components/LandingPageComp/gamersShop';
import Footer1 from '../../Components/Common/Footer1';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import TournamentPopUp from '../../Components/LandingPageComp/tournamentPopUp';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';

const LandingPage = () => {
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const isMobile = useScreenSize();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (accessToken) {
            navigate('/progames');
        }
    }, [accessToken, navigate]);

    return (
        <div>
            <>
                <Cursor />
                <Preloader />
                <ScrollToTop />
                <MainHeader1 toggleMenu={toggleMenu} />
                <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                <HeroSection />
                <div>
                    <TournamentPopUp />
                </div>
                <StatsTimer />
                <GameArea />
                <HowItWorks />
                <div id="tournament">
                    {isMobile ? (
                        <TournamentListMobile
                            selectedGameId={''}
                            gameHandles={{}}
                            landing={true}
                            pageType={'public'}
                            isScrollAvailable={false}
                        />
                    ) : (
                        <ProTournamentsList2
                            selectedGameId={''}
                            gameHandles={{}}
                            landing={true}
                            pageType={'public'}
                            isScrollAvailable={false}
                        />
                    )}
                </div>
                <AboutUs />
                <JoinUs />
                <GamersShop />
                <Footer1 />
            </>
        </div>
    );
};

export default LandingPage;
