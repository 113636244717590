import React, { useEffect, useState, useContext } from 'react';
import './AgonXP.css'; // Ensure this file exists
import '../../CustomCss/customStyles.css';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';

const AgonXP = () => {
    const [currentXP, setCurrentXP] = useState(0); // ✅ State for current XP
    const [currentTier, setCurrentTier] = useState(''); // ✅ State for current Tier
    const [nextTierXP, setNextTierXP] = useState(null); // ✅ State for XP required for next tier
    const [nextTier, setNextTier] = useState(''); // ✅ State for next Tier
    const [isLoading, setIsLoading] = useState(true); // ✅ Loading state
    const [error, setError] = useState(null); // ✅ Error state
    const [tiers, setTiers] = useState([]); // ✅ State for tier list
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    /** ✅ Fetch User XP from API */
    useEffect(() => {
        const fetchAgonXP = async () => {
            if (!accessToken) {
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user XP');
                }

                const data = await response.json();
                console.log("Fetched AgonXP Data:", data);

                setCurrentXP(data?.user_data?.AgonXP || 0);
                setCurrentTier(data?.user_data?.AgonXP_tier || 'Unknown');
                setNextTier(data?.user_data?.AgonXP_next_tier || '-');
                setNextTierXP(data?.user_data?.AgonXP_next_tier_min || null);
            } catch (err) {
                console.error('Error fetching AgonXP:', err);
                setError('Error loading AgonXP');
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgonXP();
    }, [accessToken]);

    /** ✅ Fetch Tiers from API */
    useEffect(() => {
        const fetchTiers = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${BASE_URL}/loyalty_tiers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch tiers');
                }

                const data = await response.json();
                console.log("Fetched Tiers Data:", data);

                setTiers(data?.agon_tiers?.agon_tiers || []);
            } catch (err) {
                console.error('Error fetching tiers:', err);
                setError('Error loading AgonXP tiers');
            } finally {
                setIsLoading(false);
            }
        };

        fetchTiers();
    }, []);

    /** ✅ Render Section */
    return (
        <div className={`agonxp-container ${!accessToken ? 'no-token' : ''}`}>
            <div className="title-area text-center" style={{marginTop: "120px", color: "#fff"}}>
                <span className="sub-title style1"># Loyatly</span>
                <h2>
                    Agon Loyalty Program<span className="text-theme">!</span>
                </h2>
            </div>          
            {/* ✅ Explanation Section */}
            <section className="agonxp-explanation">
                <div className="agonxp-explanation-card">
                    <p>
                        Unlock <strong>exclusive rewards</strong> based on your <strong>tier</strong>, determined by the <strong>ACoins</strong> you earn each month.
                    </p>
                    <p>
                        Earn ACoins by <strong>winning tournaments</strong>, <strong>inviting friends</strong>, and <strong>completing goals</strong>.
                    </p>
                    <p>
                        The more ACoins you earn, the <strong>higher your tier</strong> and the <strong>better your rewards</strong>.🚀
                    </p>
                </div>
            </section>


            {/* ✅ AgonXP Counter Section */}
            {accessToken && (
                <section className="agonxp-counter">
                    <h2>📊 Your Acoins Progress</h2>
                    <div className="counter">
                        {isLoading ? (
                            <p>Loading your AgonXP...</p>
                        ) : error ? (
                            <p className="error-message">{error}</p>
                        ) : (
                            <>
                                <h3>
                                    Current: <CountUp start={0} end={parseInt(currentXP || 0)} duration={2} /> ACoins
                                </h3>
                                <p className="current-tier-highlight">
                                    Current Tier: <strong>{currentTier}</strong>
                                </p>
                                {nextTierXP !== null && nextTierXP > currentXP ? (
                                    <p>
                                        You're just <strong>{parseInt(nextTierXP || 0) - parseInt(currentXP || 0)} ACoins</strong> away
                                        from reaching the <strong>{nextTier}!</strong>
                                    </p>
                                ) : (
                                    <p>
                                        You've reached the <strong>highest tier!</strong>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </section>
            )}

            {/* ✅ AgonXP to Tier Mapping */}
            <section className="agonxp-tiers">
                <h2>🏆 Agon Tiers & Benefits</h2>
                <div className="tiers-grid">
                    {tiers.map((tier) => (
                        <div
                            key={tier.level}
                            className={`tier-card ${tier.level === currentTier ? 'highlighted-tier' : ''}`}
                        >
                            <h3>{tier.level}</h3>
                            <p>
                                <strong>ACoins Range:</strong> {tier.range}
                            </p>
                            <p>{tier.benefits}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* ✅ Ways to Earn ACoins */}
            <section className="agonxp-ways">
                <h2>💎 Ways to Earn AgonXP</h2>
                <div className="ways-list">
                    {[
                        { method: '🎯 Winning Tournaments', description: 'Earn ACoins for every tournament win.', link: '/progames' },
                        { method: '📅 Daily Login Streak', description: 'Get bonus for consecutive daily logins.', link: '/progames' },
                        { method: '🏆 Leaderboard Rankings', description: 'Higher ranks mean more ACoin rewards.', link: '/leaderboard' },
                        { method: '🤝 Referring Friends', description: 'Get ACoins for every successful referral.', link: '/my-profile' },
                        { method: '🛒 Redeeming Rewards', description: 'Earn by spending in the rewards marketplace.', link: '/my-rewards' },
                    ].map((way) => (
                        <div
                            key={way.method}
                            className="way-card"
                            onClick={() => handleNavigation(way.link)}
                        >
                            <h3>{way.method}</h3>
                            <p>{way.description}</p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default AgonXP;
