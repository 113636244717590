import React, { useEffect, useState, useContext } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const AgonPartners = () => {
    const [referrals, setReferrals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { accessToken } = useContext(AuthContext);
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState(''); // State to store display_name

    // Fetch referral data on mount
    useEffect(() => {
        const fetchReferralData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/referral_activity`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    setReferrals(result.data || []);
                } else if (response.status === 404) {
                    setError('No referral activity found.');
                } else {
                    setError(result.message || 'Failed to fetch referral activity.');
                }
            } catch (e) {
                setError('An error occurred while fetching referral activity.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchReferralData();
    }, [accessToken]);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    // Render referral cards
    const renderReferralCards = () => {
        if (referrals.length === 0) {
            return <div>No referrals found.</div>;
        }

        return referrals.map((referral) => (
            <div key={referral.referred_user_id} className="referral-card">
                <h2>{referral.referred_username}</h2>
                <p>Joined Date: <strong> {referral.referred_joined_at || 'N/A'}</strong> </p>
                <p>Tournaments Joined: <strong>{referral.num_tournaments_joined || 0}</strong></p>
                <p>Winnings: <strong>{(referral.total_winnings || 0).toFixed(2)} ACash</strong></p>
                <p>Bonus: <strong>{(referral.total_bonus || 0).toFixed(2)} ACoins</strong></p>
            </div>
        ));
    };

    // Function to get the current month and year in the desired format
    const getCurrentMonthYear = () => {
        const date = new Date();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
        return `${month} '${year}`;
    };

    // Render loading spinner or error message
    if (isLoading) {
        return (
            <div className="central-message">
                <div>Loading referral activity...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="central-message">
                <div className="error-message">{error}</div>
            </div>
        );
    }

    const fetchUserProfile = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();

            if (data.code === 200 && data.user_data) {
                const { invite_code, display_name } = data.user_data;
                setInviteCode(invite_code || '');
                setUsername(display_name || 'User'); // Set username from display_name
            } else {
                console.error('Failed to load profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const shareLink = `https://agongames.com?r=${inviteCode}`;

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Join me on AgonGames!',
                    text: 'Hey, check out AgonGames and earn real rewards by playing tournaments across popular games!',
                    url: shareLink,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Sharing is not supported on this device.');
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(shareLink);
        alert('Referral link copied to clipboard!');
    };


    return (
        <div className="agon-partners-container">
        {/* Invite A Friend Section */}
        {/*
        <div className="invite-friend-card">
            <h2>Agon Partners Program</h2>
            <p>
                Join the Agon-Partners program and earn rewards for every member's activity! 
                Earnings upto ₹12,000 is just a start. Calculate your potential earnings 
                <a href="https://agongames.com/TAG_calculator.html" target="_blank" rel="noopener noreferrer">
                <s/ >   here
                </a>.
                <br />
                Let’s level up together! 🚀🎉
            </p>
        */}
            {/* Display the Share URL */}
        {/*
            <div className="share-link-container">
                <input
                    type="text"
                    value={shareLink}
                    readOnly
                    className="share-url-input"
                    aria-label="Referral Link"
                />
                <button className="copy-btn" onClick={handleCopy}>
                    Copy Link
                </button>
            </div>

            <button className="th-btn style1 col-auto share-btn" onClick={handleShare}>
                Share
            </button>
        </div> */}
        <h1 className="page-heading" style={{marginTop: "80px"}}>Referral Activity</h1>
        <h2 className="page-subtitle">Your Referrals Engagement - {getCurrentMonthYear()}</h2>
        <div id="referral-container">{renderReferralCards()}</div>
        </div>
    );
};

export default AgonPartners;
